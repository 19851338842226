import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { getFSDemmiConstants } from '@core/services/networkService/functions/_demmi';
import { getProducts } from '@core/services/networkService/functions/_product';
import { SearchParams } from '@core/services/routingService';
import {
  selectDemmiConstants,
  selectVendor,
  setDemmiConstants,
} from '@core/store/appSlice/appSlice';
import { demmiRequest } from '@helpers/app.helper';
import { CSSVARS, setCSSVar } from '@helpers/theme.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

import InventorySidebar from './Sidebar';
import ProductDetails from './productDetails/ProductDetails';
import ProductVariants from './productVariants/ProductVariants';

const Inventory: React.FC = () => {
  const CSSBlock = 'inventory';
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const vendor = useAppSelector(selectVendor);
  const demmiConstants = useAppSelector(selectDemmiConstants);
  const [products, setProducts] = useState<DemmiFS.Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<
    DemmiFS.Product | undefined
  >();
  const [livePreview, setLivePreview] = useState(false);

  useEffect(() => {
    if (!demmiConstants)
      demmiRequest(getFSDemmiConstants()).then(o =>
        dispatch(setDemmiConstants(o)),
      );
  }, [demmiConstants]);

  useEffect(() => {
    setProducts([]);
    if (vendor) {
      setCSSVar(CSSVARS.PHONE_PREVIEW_HUE, vendor.themeHue.toString());
      refreshProducts().then(products => {
        if (products && searchParams.get(SearchParams.PRODUCT_ID)) {
          setSelectedProduct(
            products.find(
              p => p.docID === searchParams.get(SearchParams.PRODUCT_ID),
            ),
          );
        }
      });
    }
  }, [vendor]);

  const refreshProducts = (id?: string) => {
    if (vendor) {
      return demmiRequest(getProducts(vendor.docID)).then(products => {
        setProducts(products);
        setSelectedProduct(id ? products.find(p => p.docID === id) : undefined);
        return products;
      });
    } else return Promise.resolve();
  };

  const onCreated = async (id: string) => {
    refreshProducts(id);
  };

  const onClickRefresh = () => {
    refreshProducts();
  };

  const onClickProduct = (id: string) => {
    setSelectedProduct(undefined);
    setSearchParams({ [SearchParams.PRODUCT_ID]: id });
    setTimeout(() => {
      const product = products.find(p => p.docID === id);
      if (product) setSelectedProduct(product);
    }, 50);
  };

  // const onDelete = (id: string) => {
  //   demmiRequest(deleteProduct(id)).then(() => {
  //     setProducts([]);
  //     if (vendor)
  //       demmiRequest(getProducts(vendor.docID)).then(products => {
  //         setProducts(products);
  //         setSelectedProduct(undefined);
  //       });
  //   });
  // };

  const onChangeArchived = (id: string) => {
    refreshProducts(id);
  };

  return (
    <div
      className={`${CSSBlock} ${
        livePreview && selectedProduct ? `${CSSBlock}--live-preview` : ''
      }`}>
      <InventorySidebar
        active={selectedProduct?.docID ?? ''}
        products={products}
        onClick={onClickProduct}
        onClickRefresh={onClickRefresh}
        onCreate={onCreated}
        onClickPreview={() => setLivePreview(!livePreview)}
      />
      <div className={`${CSSBlock}__details`}>
        {demmiConstants && selectedProduct && (
          <>
            <ProductDetails
              product={selectedProduct}
              onChangeArchived={onChangeArchived}
              demmiConstants={demmiConstants}
            />
            <ProductVariants product={selectedProduct} />
          </>
        )}
      </div>
    </div>
  );
};

export default Inventory;
