import React, { useEffect, useRef, useState } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { createProductVariant } from '@core/services/networkService/functions/_productVariants';
import { selectVendor } from '@core/store/appSlice/appSlice';
import DemmiButton from '@demmi-ui/Button';
import DemmiIconButton from '@demmi-ui/IconButton';
import DemmiCardButtonArea from '@demmi-ui/Modal/ModalButtonArea';
import DemmiModalCore, { DemmiModalCoreRef } from '@demmi-ui/Modal/ModalCore';
import { demmiRequest } from '@helpers/app.helper';
import { isProductDetailsValid } from '@helpers/products.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

import ModalCreateVariantContent from './ModalCreateVariantContent';

interface Props {
  product: DemmiFS.Product;
  onClose: () => void;
  onConfirm: () => void;
}

const ModalCreateVariant: React.FC<Props> = ({
  product,
  onClose,
  onConfirm,
}) => {
  const CSSBlock = 'modal-create-variant';
  const vendor = useAppSelector(selectVendor);
  const modalRef = useRef<DemmiModalCoreRef>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [variant, setVariant] = useState<DemmiFS.ProductVariant>();

  /**
   * 0 = Doing nothing
   * 1 = Creating product
   * 2 = Uploading image
   * -1 = Failed to create
   * -2 = Failed to upload image
   */
  const [_, setState] = useState(0);

  useEffect(() => {
    if (product) setIsValid(isProductDetailsValid(product!));
    else setIsValid(false);
  }, [product]);

  const [isValid, setIsValid] = useState(false);

  const gracefulExit = (postExit: () => void) => {
    modalRef.current ? modalRef.current.gracefulClose(postExit) : postExit();
    setTimeout(() => {
      setIsOpen(false);
    }, 500);
  };

  const pushVariant = async () => {
    if (isValid && variant) {
      const { docID: _0, productID: _1, ...newVariant } = variant;
      demmiRequest(createProductVariant(product.docID, newVariant))
        .then(_ => {
          setState(0);
          gracefulExit(() => onConfirm());
        })
        .catch(() => setState(-1));
    }
  };

  const buttons = [
    <DemmiButton
      key={0}
      text="Discard"
      outlined
      onClick={() => gracefulExit(onClose)}
    />,
    <DemmiButton
      key={1}
      text="Create Variant"
      disabled={!isValid}
      onClick={() => pushVariant()}
    />,
  ];

  return (
    <>
      <DemmiIconButton faIcon="fa-plus" onClick={() => setIsOpen(true)} />
      <DemmiModalCore
        ref={modalRef}
        isOpen={isOpen}
        onExited={() => {
          setIsOpen(false);
          onClose();
        }}
        contentClassName={CSSBlock}
        allowBackdropClose={false}>
        <ModalCreateVariantContent
          CSSBlock={CSSBlock}
          countryCode={vendor?.address?.countryCode ?? ''}
          setVariant={setVariant}
        />
        <DemmiCardButtonArea buttons={buttons} />
      </DemmiModalCore>
    </>
  );
};

export default ModalCreateVariant;
