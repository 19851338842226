import { Timestamp } from 'firebase/firestore';
import { DateTime } from 'luxon';
import React, { Dispatch, useEffect, useState } from 'react';

import DemmiCard from '@demmi-ui/Card/Card';
import DemmiCardDivider from '@demmi-ui/Card/CardDivider';
import DemmiInput from '@demmi-ui/Input';
import DemmiModalArea from '@demmi-ui/Modal/ModalArea';
import DemmiToggle from '@demmi-ui/Toggle';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  coupon: DemmiFS.Coupon;
  state: number;
  CSSBlock: string;
  setCoupon: React.Dispatch<React.SetStateAction<DemmiFS.Coupon | undefined>>;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalEditCouponContent: React.FC<Props> = ({
  coupon,
  state,
  CSSBlock,
  setCoupon,
  setIsValid,
}) => {
  const [hasStartDate, setHasStartDate] = useState(!!coupon.startDate);
  const [hasExpirationDate, setHasExpirationDate] = useState(
    !!coupon.expirationDate
  );
  const [startDate, setStartDate] = useState<Timestamp | undefined>(
    DemmiFS.getTimestampFromFieldValue(coupon.startDate)
  );
  const [expirationDate, setExpirationDate] = useState<Timestamp | undefined>(
    DemmiFS.getTimestampFromFieldValue(coupon.expirationDate)
  );

  useEffect(() => {
    setHasStartDate(!!startDate);
    setHasExpirationDate(!!expirationDate);
  }, [startDate, expirationDate]);

  useEffect(() => {
    setIsValid(DemmiFS.CouponHelper.isDatesValid(startDate, expirationDate));
    setCoupon(updatedCoupon());
  }, [hasStartDate, startDate, hasExpirationDate, expirationDate]);

  const updatedCoupon = (): DemmiFS.Coupon => ({
    ...coupon,
    startDate: hasStartDate ? startDate : undefined,
    expirationDate: hasExpirationDate ? expirationDate : undefined,
  });

  const formatFieldValue = (timestamp?: Timestamp) => {
    if (!timestamp || !DemmiFS.getDateFromFirestore(timestamp)) return;
    return DateTime.fromJSDate(
      DemmiFS.getDateFromFirestore(timestamp!)!
    ).toFormat('yyyy-MM-dd');
  };

  const updateFieldValue = (
    value: string,
    setter: Dispatch<React.SetStateAction<Timestamp | undefined>>
  ) => {
    setter(
      !isNaN(Date.parse(value))
        ? Timestamp.fromDate(new Date(`${value} 00:00:00`))
        : undefined
    );
  };

  return (
    <>
      <DemmiModalArea isDark>
        <div className={`${CSSBlock}__title`}>Modify your coupon dates</div>
        <div className={`${CSSBlock}__content`}>
          <DemmiCard>
            <div className={`${CSSBlock}__code`}>{coupon.docID}</div>

            {state === -1 && (
              <div className={`${CSSBlock}__error`}>
                Failed to update coupon.
              </div>
            )}

            <DemmiCardDivider />
            <div className={`${CSSBlock}__toggle-item`}>
              <DemmiToggle
                isActive={hasStartDate}
                text="Start Date"
                onToggle={v => setHasStartDate(v)}
              />
              {hasStartDate && (
                <>
                  <DemmiInput
                    type="date"
                    value={formatFieldValue(startDate)}
                    onChangeValue={val => updateFieldValue(val, setStartDate)}
                  />
                  <br />
                  <br />
                </>
              )}
            </div>
            <div className={`${CSSBlock}__toggle-item`}>
              <DemmiToggle
                isActive={hasExpirationDate}
                text="Expiration Date?"
                onToggle={v => setHasExpirationDate(v)}
              />
              {hasExpirationDate && (
                <DemmiInput
                  type="date"
                  value={formatFieldValue(expirationDate)}
                  onChangeValue={val =>
                    updateFieldValue(val, setExpirationDate)
                  }
                />
              )}
            </div>
          </DemmiCard>
        </div>
      </DemmiModalArea>
    </>
  );
};

export default ModalEditCouponContent;
