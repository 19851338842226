import { MenuItem } from '@demmi-ui/Menu/MenuTypes';
import { DemmiFS } from '@subhanhabib/demmilib';

const _categoryToMenuItem = <T extends DemmiFS.Category>(c: T): MenuItem<T> => {
  return {
    id: c.id,
    text: c.name,
    item: c,
  };
};

export const getMenuContent = <T extends DemmiFS.Category>(
  categories: T[],
  optionalItems?: T[]
) => {
  return {
    sections: [
      {
        items: categories
          ? [
              ...categories
                .slice()
                .sort((a, b) =>
                  a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
                ),
              ...(optionalItems ?? []),
            ].map(c => _categoryToMenuItem<T>(c))
          : [],
      },
    ],
  };
};
