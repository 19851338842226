import React from 'react';

import DemmiInput from '@demmi-ui/Input';

import Logo from '../../assets/demmi-logo.svg';

interface SignInCardProps {
  inputEmail: string;
  setInputEmail: React.Dispatch<React.SetStateAction<string>>;
  inputPassword: string;
  setInputPassword: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: () => void;
}

const LoginCard: React.FC<SignInCardProps> = ({
  inputEmail,
  setInputEmail,
  inputPassword,
  setInputPassword,
  onSubmit,
}) => {
  const CSSBlock = 'login-card';

  return (
    <div className={`${CSSBlock}`}>
      {/* <DemmiCardArea> */}
      <div className={`${CSSBlock}__content`}>
        <div className={`${CSSBlock}__title`}>
          <img src={Logo} />
          <span>Portal</span>
        </div>
        <div className={`${CSSBlock}__description`}>
          Enter your login credentials below
        </div>
        <DemmiInput
          type="email"
          placeholder="Enter your email"
          value={inputEmail}
          onChangeValue={val => setInputEmail(val)}
        />
        <DemmiInput
          type="password"
          placeholder="Enter your password"
          value={inputPassword}
          onChangeValue={val => setInputPassword(val)}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') onSubmit();
          }}
        />
      </div>
      {/* </DemmiCardArea> */}
    </div>
  );
};

export default LoginCard;
