import React from 'react';

import DemmiCard from '@demmi-ui/Card/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DemmiFS } from '@subhanhabib/demmilib';
import { faStar } from '@fortawesome/pro-regular-svg-icons';
import { faStars } from '@fortawesome/pro-solid-svg-icons';

interface Props {
  review: DemmiFS.VendorReview;
}

const WidgetReviewItem: React.FC<Props> = ({ review }) => {
  const CSSBlock = 'widget-review-item';

  return (
    <DemmiCard>
      <div className={CSSBlock}>
        <div className={`${CSSBlock}__header`}>
          <div className={`${CSSBlock}__timestamp`}>
            Reviewed on:
            <span>
              {DemmiFS.getDateFromFirestore(review.timestamp)?.toDateString()}
            </span>
          </div>
          <div className={`${CSSBlock}__rating`}>
            {[...Array(5)].map((_, i) => (
              <FontAwesomeIcon
                key={i}
                icon={review!.rating * 5 < i ? faStar : faStars}
              />
            ))}
          </div>
        </div>

        <div className={`${CSSBlock}__review`}>
          <div className={`${CSSBlock}__review-title`}>{review.title}</div>
          <div className={`${CSSBlock}__review-description`}>
            {review.body.map((paragraph, i) => (
              <div
                className={`${CSSBlock}__review-description-paragraph`}
                key={i}>
                {paragraph}
              </div>
            ))}
          </div>
        </div>
      </div>
    </DemmiCard>
  );
};

export default WidgetReviewItem;
