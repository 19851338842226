import { LSKeys, LocalStorage } from '@core/localStorage';
import { ThemeMode, ThemeModeConstants } from '@helpers/theme.helper';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DemmiFS } from '@subhanhabib/demmilib';

import { RootState } from '../store';

interface AppState {
  themeMode: ThemeMode;
  vendor?: DemmiFS.Vendor;
  firestoreDemmi?: DemmiFS.DemmiConstants;
  activeRequests: string[];
  globalContentMounted: boolean;
  demmiSupportOpen: boolean;
  demmiSupportChatID: string | undefined;
}

const initialState: AppState = {
  themeMode: LocalStorage.get(LSKeys.THEME_MODE) as ThemeMode,
  vendor: undefined,
  firestoreDemmi: undefined,
  activeRequests: [],
  globalContentMounted: false,
  demmiSupportOpen: false,
  demmiSupportChatID: undefined,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  extraReducers: builder => {
    builder.addCase('LOGOUT', state => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setThemeMode: (state, action: PayloadAction<ThemeModeConstants>) => {
      state.themeMode = action.payload;
    },

    pushNetworkRequestUUID: (
      state,
      { payload: uuid }: PayloadAction<string>,
    ) => {
      state.activeRequests = [...state.activeRequests, uuid];
    },

    popNetworkRequestUUID: (
      state,
      { payload: uuid }: PayloadAction<string>,
    ) => {
      state.activeRequests = state.activeRequests.filter(
        request => request !== uuid,
      );
    },
    setVendor: (
      state,
      { payload: vendor }: PayloadAction<DemmiFS.Vendor | undefined>,
    ) => {
      state.vendor = vendor;
    },
    setDemmiConstants: (
      state,
      { payload }: PayloadAction<DemmiFS.DemmiConstants | undefined>,
    ) => {
      state.firestoreDemmi = payload;
    },
    setGlobalContentMounted: (state, { payload }: PayloadAction<boolean>) => {
      if (state.globalContentMounted !== payload)
        state.globalContentMounted = payload;
    },
    setDemmiSupportOpen: (state, { payload }: PayloadAction<boolean>) => {
      if (state.demmiSupportOpen !== payload) state.demmiSupportOpen = payload;
    },
    setDemmiSupportChatID: (
      state,
      { payload }: PayloadAction<string | undefined>,
    ) => {
      state.demmiSupportChatID = payload;
    },
  },
});

export const {
  setThemeMode,
  pushNetworkRequestUUID,
  popNetworkRequestUUID,
  setVendor,
  setDemmiConstants,
  setGlobalContentMounted,
  setDemmiSupportOpen,
  setDemmiSupportChatID,
} = appSlice.actions;

export const selectThemeMode = (state: RootState) => state.app.themeMode;
export const selectVendor = (state: RootState) => state.app.vendor;
export const selectDemmiConstants = (state: RootState) =>
  state.app.firestoreDemmi;
export const selectIsLoading = (state: RootState) =>
  state.app.activeRequests.length > 0;
export const selectActiveRequests = (state: RootState) =>
  state.app.activeRequests;
export const selectGlobalContentMounted = (state: RootState) =>
  state.app.globalContentMounted;
export const selectDemmiSupportOpen = (state: RootState) =>
  state.app.demmiSupportOpen;
export const selectDemmiSupportChatID = (state: RootState) =>
  state.app.demmiSupportChatID;

export default appSlice.reducer;
