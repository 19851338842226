import React, { useEffect, useState } from 'react';

import {
  CSSVARS,
  DemmiTheme,
  setCSSPalette,
  setCSSVar,
} from '@helpers/theme.helper';

import { useAppSelector } from './hooks/appHooks';
import { selectVendor } from './store/appSlice/appSlice';

const fps = 60;
const duration = 2;
const easeInOutSine = (x: number) => -(Math.cos(Math.PI * x) - 1) / 2;

interface AppThemeProps {
  isLogin: boolean;
}

let themeInterval = 0;

const AppTheme: React.FC<AppThemeProps> = ({ isLogin }) => {
  const vendor = useAppSelector(selectVendor);
  const [themeHue, setThemeHue] = useState(Math.round(Math.random() * 360));

  useEffect(() => {
    if (vendor) setThemeHue(vendor.themeHue);
  }, [vendor]);

  const animate = () => {
    requestAnimationFrame(() => {
      if (process.env.IS_PRODUCTION) {
        animatePos();
      }
    });
  };

  const onSetThemeHue = () => {
    const colour = DemmiTheme.get(themeHue);
    setCSSPalette(colour.palette);
    setCSSVar(CSSVARS.THEME_BG_HUE, colour.hue);
    document.body.classList.toggle(
      `palette-light`,
      themeHue >= 60 && themeHue <= 180,
    );
    setCSSVar(
      CSSVARS.THEME_BG_01,
      `hsla(${colour.palette[400].h + 15}, ${colour.palette[400].s}%, ${
        colour.palette[400].l
      }%, 1)`,
    );
    setCSSVar(
      CSSVARS.THEME_BG_02,
      `hsla(${colour.palette[400].h - 15}, ${colour.palette[400].s}%, ${
        colour.palette[400].l
      }%, 1)`,
    );
  };

  let pos = 0;
  const animatePos = () => {
    pos += 1 / fps / duration;
    setCSSVar(CSSVARS.THEME_BG_POS, (0 + easeInOutSine(pos) * 100).toString());
  };

  useEffect(() => {
    if (!isLogin) onSetThemeHue();
  }, [themeHue]);

  useEffect(() => {
    themeInterval = window.setInterval(animate, 1000 / fps);
    return () => clearInterval(themeInterval);
  }, [isLogin]);

  useEffect(() => {
    return () => clearInterval(themeInterval);
  }, []);

  return <></>;
};

export default AppTheme;
