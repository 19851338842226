import { Timestamp } from 'firebase/firestore';
import React, { useState } from 'react';

import ElementLoadingIndicator from '@components/loadingIntro/ElementLoadingIndicator';
import { updateOrderTimeline } from '@core/services/networkService/orders/orders';
import DemmiButton from '@demmi-ui/Button';
import DemmiTextArea from '@demmi-ui/TextArea';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  CSSBlock: string;
  vendorID: string;
  order: DemmiFS.Order;
}

const OrderItemTabTimelineUpdate: React.FC<Props> = ({
  CSSBlock,
  vendorID,
  order,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [text, setText] = useState('');

  const onClickUpdate = () => {
    setIsUpdating(true);
    updateOrderTimeline(order.docID, {
      text,
      timestamp: Timestamp.now(),
      vendorID,
      updatedBy: DemmiFS.OrderTimelineHelper.UpdatedBy.VENDOR,
    }).then(() => {
      setIsUpdating(false);
      setText('');
    });
  };

  return (
    <div
      className={`${CSSBlock}__update ${
        isUpdating ? `${CSSBlock}__update--is-loading` : ``
      }`}>
      <div className={`${CSSBlock}__update-title`}>Update status</div>
      <DemmiTextArea
        placeholder="Enter an update"
        value={text}
        onChange={t => setText(t)}
      />
      <div className={`${CSSBlock}__buttons`}>
        <DemmiButton
          text="Send Update"
          faIcon="fa-paper-plane-top"
          onClick={onClickUpdate}
        />
      </div>
      <ElementLoadingIndicator isLoading={isUpdating} />
    </div>
  );
};

export default OrderItemTabTimelineUpdate;
