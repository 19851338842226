import React from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface Props {
  title?: string;
  text: string;
  icon?: IconDefinition;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  styles?: React.CSSProperties;
}

const MenuTriggerButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ title, text, icon: faIcon, disabled, onClick, styles }: Props, ref) => {
    const CSSBlock = 'demmi-ui-menu-trigger-button';

    return (
      <button
        className={`demmi-ui-button ${CSSBlock} ${
          title ? `${CSSBlock}--has-title` : ''
        }`}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
        style={styles}>
        <div className={` ${CSSBlock}__content`}>
          {faIcon && (
            <span className={`${CSSBlock}__icon`}>
              <FontAwesomeIcon icon={faIcon} />
            </span>
          )}
          <span className={`${CSSBlock}__text`}>
            {title && (
              <span className={`${CSSBlock}__text-title`}>{title}</span>
            )}
            <span className={`${CSSBlock}__text-main`}>{text}</span>
          </span>
        </div>
        <span className={`${CSSBlock}__dropdown-icon`}>
          <FontAwesomeIcon icon={faCaretDown} />
        </span>
      </button>
    );
  },
);

MenuTriggerButton.displayName = 'MenuTriggerButton';
export default MenuTriggerButton;
