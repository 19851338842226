import {
  DocumentData,
  QueryDocumentSnapshot,
  Timestamp,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';

import { addDoc } from '@firebase/firestore';
import { FSCollections } from '@providers/firestoreProvider';
import { DemmiFS, DemmiLogType, Logger } from '@subhanhabib/demmilib';

import { db, storage } from '../../../../firebase';
import { FSCollectionNames, StoragePaths } from '../networkService';
import { getProductVariants } from './_productVariants';

export const createProduct = async (
  product: DemmiFS.FSProduct,
): Promise<string> => {
  Logger({ objs: { product } }, createProduct);
  const docRef = await addDoc(FSCollections.Products, {
    ...product,
    isArchived: product.isArchived === undefined ? false : product.isArchived,
    createdAt: Timestamp.fromDate(new Date()),
    updatedAt: Timestamp.fromDate(new Date()),
  });
  return docRef.id;
};

export const getProducts = async (
  vendorID: string,
  includeVariants = false,
): Promise<DemmiFS.Product[]> => {
  Logger({ objs: { vendorID } }, getProducts);
  const q = query(FSCollections.Products, where('vendorID', '==', vendorID));
  const querySnapshot = await getDocs(q);
  const products: DemmiFS.Product[] = [];

  if (includeVariants) {
    const variantRequests: {
      [key: string]: Promise<DemmiFS.ProductVariant[] | undefined>;
    } = {};

    querySnapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
      products.push({
        docID: doc.id,
        ...(doc.data() as DemmiFS.FSProduct),
        variants: [],
      });
      variantRequests[doc.id] = getProductVariants(doc.id);
    });

    await Promise.all(Object.values(variantRequests)).then(variants => {
      products.map(p => {
        const variantsIndex = Object.keys(variantRequests).indexOf(p.docID);
        return variantsIndex > -1
          ? { ...p, variants: variants[variantsIndex] }
          : p;
      });
    });
  } else {
    querySnapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
      products.push({
        docID: doc.id,
        ...(doc.data() as DemmiFS.FSProduct),
        variants: [],
      });
    });
  }
  return products;
};

export const getProduct = async (
  productID: string,
  includeVariants = false,
): Promise<DemmiFS.Product | undefined> => {
  Logger({ objs: { productID } }, getProduct);
  const docSnap = await getDoc(doc(db, FSCollectionNames.PRODUCTS, productID));

  if (docSnap.exists()) {
    if (includeVariants) {
      const variants = await getProductVariants(productID);
      return {
        docID: docSnap.id,
        ...(docSnap.data() as DemmiFS.FSProduct),
        variants,
      };
    } else {
      return {
        docID: docSnap.id,
        ...(docSnap.data() as DemmiFS.FSProduct),
        variants: [],
      };
    }
  } else {
    Logger(
      {
        messages: ['No such document!'],
        objs: { productID },
        type: DemmiLogType.error,
      },
      getProduct,
    );
  }
  return undefined;
};

export const updateProduct = async (
  product: DemmiFS.Product,
): Promise<void> => {
  Logger({ objs: { product } }, updateProduct);
  const docRef = doc(FSCollections.Products, product.docID);
  return updateDoc(docRef, {
    name: product.name,
    categoryID: product.categoryID,
    shortDescription: product.shortDescription,
    longDescription: product.longDescription,
    images: product.images,
    tags: product.tags,
    updatedAt: Timestamp.fromDate(new Date()),
  });
};

export const updateProductPublished = async (
  pID: string,
  isPublished: boolean,
): Promise<void> => {
  Logger({ objs: { pID, isPublished } }, updateProductPublished);
  const docRef = doc(FSCollections.Products, pID);
  return updateDoc(docRef, {
    isPublished: isPublished,
    updatedAt: Timestamp.fromDate(new Date()),
  });
};

export const updateProductImages = async (
  id: string,
  images: DemmiFS.DemmiImageResource[],
): Promise<void> => {
  Logger({ objs: { id, images } }, updateProductImages);
  const docRef = doc(FSCollections.Products, id);
  return updateDoc(docRef, {
    images,
    updatedAt: Timestamp.fromDate(new Date()),
  });
};

export const checkProductImageExists = async (
  vendorDocID: string,
  imageURL: string,
) => {
  const pathReference = ref(
    storage,
    `/${vendorDocID}/${StoragePaths.PRODUCT_IMAGES}/${imageURL}`,
  );
  return getDownloadURL(pathReference)
    .then(_ => true)
    .catch(_ => false);
};

export const deleteProduct = async (pID: string): Promise<void> => {
  Logger({ objs: { pID } }, deleteProduct);
  const docRef = doc(FSCollections.Products, pID);
  return deleteDoc(docRef);
};

export const updateProductArchived = async (
  pID: string,
  isArchived: boolean,
): Promise<void> => {
  Logger({ objs: { pID, isArchived } }, updateProductArchived);
  const docRef = doc(FSCollections.Products, pID);
  return updateDoc(docRef, {
    isArchived: isArchived,
    updatedAt: Timestamp.fromDate(new Date()),
  });
};
