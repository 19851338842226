import React from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export enum IconButtonSize {
  LARGE = 'large',
  SMALL = 'small',
  MINI = 'mini',
}

interface DemmiIconButtonProps {
  faIcon?: string;
  icon?: IconDefinition;
  className?: string;
  size?: IconButtonSize;
  onClick?: (e: React.MouseEvent) => void;
  tabIndex?: number;
  disabled?: boolean;
  styles?: React.CSSProperties;
  overlay?: boolean;
  variant?: 'delete' | 'save' | 'transparent';
}

const CSSBlock = 'demmi-ui-icon-button';
export const faStyles = [
  'fa-solid',
  'fa-regular',
  'fa-light',
  'fa-thin',
  'fa-duotone',
];
const DemmiIconButton = React.forwardRef<
  HTMLButtonElement,
  DemmiIconButtonProps
>(
  (
    {
      faIcon,
      icon,
      className,
      onClick,
      size,
      tabIndex,
      disabled,
      styles,
      overlay,
      variant,
    }: DemmiIconButtonProps,
    ref,
  ) => {
    return (
      <button
        className={`${CSSBlock} ${size ? `${CSSBlock}--${size}` : ''} ${
          className || ''
        } ${overlay ? `${CSSBlock}--overlay` : ``} ${
          variant ? `${CSSBlock}--${variant}` : ``
        }`}
        onClick={onClick}
        ref={ref}
        disabled={disabled}
        style={styles}
        {...(tabIndex ? { tabIndex: tabIndex } : {})}>
        {icon ? (
          <FontAwesomeIcon icon={icon} />
        ) : (
          faIcon && (
            <i
              className={
                faStyles.find(item => faIcon.includes(item))
                  ? faIcon
                  : `fa-solid ${faIcon}`
              }></i>
          )
        )}
      </button>
    );
  },
);

DemmiIconButton.displayName = 'DemmiIconButton';
export default DemmiIconButton;
