import React from 'react';

import { DemmiFS } from '@subhanhabib/demmilib';

import OrderProductItem from '../partials/_order-product-item';

interface Props {
  CSSBlock: string;
  order: DemmiFS.Order;
}

const OrderItemTabProducts: React.FC<Props> = ({ CSSBlock, order }) => {
  return (
    <div className={`${CSSBlock}__products-list`}>
      {order.vendor?.products.map((product, i) => (
        <OrderProductItem product={product} key={i} />
      ))}
    </div>
  );
};

export default OrderItemTabProducts;
