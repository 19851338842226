import React, { useEffect, useState } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { selectVendor } from '@core/store/appSlice/appSlice';
import { DemmiFS } from '@subhanhabib/demmilib';

import { CSSBLOCK_SETTINGS } from '../Settings';
import Images from './Images';

const SettingsImages: React.FC = () => {
  const vendor = useAppSelector(selectVendor);
  const [images, setImages] = useState<DemmiFS.DemmiImageResource[]>([]);

  const setValues = (vendor: DemmiFS.Vendor) => {
    setImages(vendor.images);
  };

  useEffect(() => {
    if (vendor) setValues(vendor);
  }, [vendor]);

  return (
    <div className={`${CSSBLOCK_SETTINGS}__content-wrapper`}>
      <Images images={images} setImages={setImages} />
    </div>
  );
};

export default SettingsImages;
