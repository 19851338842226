import React from 'react';

const ComingSoon: React.FC = () => {
  const CSSBlock = 'coming-soon';

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__title`}>.:. Coming Soon .:.</div>
      <div className={`${CSSBlock}__description`}>
        We&apos;re eagerly crafting this feature to make your experience even
        better! Hang tight and keep an eye out for updates.
      </div>
      <div className={`${CSSBlock}__description`}>
        Thank you for your patience and continued support!
      </div>
    </div>
  );
};

export default ComingSoon;
