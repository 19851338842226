import React from 'react';

import AsyncImage from '@components/asyncImage/AsyncImage';
import { DemmiFS } from '@subhanhabib/demmilib';

export interface Props {
  product: DemmiFS.Product;
  active?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

const InventorySidebarButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ product, active, disabled, onClick }: Props, ref) => {
    const CSSBlock = 'sidebar-button';

    return (
      <button
        className={`
          ${CSSBlock} ${CSSBlock}--inventory 
          ${active ? `${CSSBlock}--active` : ``} 
          ${product.isArchived ? `${CSSBlock}--archived` : ''}`}
        disabled={disabled}
        onClick={onClick}
        ref={ref}>
        <div className={`${CSSBlock}__image`}>
          {product.images.length > 0 && (
            <AsyncImage src={product.images[0].thumbnail} />
          )}
          {product.isArchived && (
            <div className={`${CSSBlock}__status`}>Archived</div>
          )}
        </div>
        <span className={`${CSSBlock}__text`}>{product.name}</span>
      </button>
    );
  },
);

InventorySidebarButton.displayName = 'InventorySidebarButton';
export default InventorySidebarButton;
