import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';

import { FSCollections } from '@providers/firestoreProvider';
import {
  DemmiFS,
  DemmiHelpers,
  DemmiLogType,
  Logger,
} from '@subhanhabib/demmilib';

import { db, storage } from '../../../../firebase';
import { FSCollectionNames, StoragePaths } from '../networkService';

export const getVendorUser = async (
  vendorerID: string,
): Promise<DemmiFS.VendorUser | undefined> => {
  Logger({ objs: { vendorerID } }, getVendorUser);
  const snap = await getDoc(
    doc(db, FSCollectionNames.VENDOR_USERS, vendorerID),
  );
  if (snap.exists()) {
    return {
      docID: snap.id,
      ...(snap.data() as DemmiFS.FSVendorUser),
    };
  } else {
    Logger(
      { messages: ['No such document!'], type: DemmiLogType.error },
      getVendorUser,
    );
  }
  return undefined;
};

export const getVendor = async (
  vendorID: string,
): Promise<DemmiFS.Vendor | undefined> => {
  Logger({ objs: { vendorID } }, getVendor);
  const snap = await getDoc(doc(db, FSCollectionNames.VENDORS, vendorID));

  if (snap.exists()) {
    const newVendor: DemmiFS.FSVendor = snap.data() as DemmiFS.FSVendor;
    if (!newVendor.shop?.openingHours) {
      newVendor.shop = {
        openingHours: DemmiHelpers.Vendor.EmptyOpeningHours,
      };
    }
    return {
      docID: snap.id,
      ...newVendor,
    };
  } else {
    Logger(
      { messages: ['No such document!'], type: DemmiLogType.error },
      getVendor,
    );
  }
  return undefined;
};

export const updateVendorDetails = async (
  vendor: DemmiFS.Vendor,
): Promise<void> => {
  Logger({ objs: { vendor } }, updateVendorDetails);
  const docRef = doc(FSCollections.Vendors, vendor.docID);
  return updateDoc(docRef, {
    name: vendor.name,
    contactNumber: vendor.contactNumber,
    shortDescription: vendor.shortDescription,
    longDescription: vendor.longDescription,
  });
};

export const updateVendorTheme = async (
  vendor: DemmiFS.Vendor,
): Promise<void> => {
  Logger({ objs: { vendor } }, updateVendorTheme);
  const docRef = doc(FSCollections.Vendors, vendor.docID);
  return updateDoc(docRef, {
    themeHue: vendor.themeHue,
  });
};

export const updateVendorHours = async (
  vendor: DemmiFS.Vendor,
): Promise<void> => {
  Logger({ objs: { vendor } }, updateVendorHours);
  const docRef = doc(db, FSCollectionNames.VENDORS, vendor.docID);
  return updateDoc(docRef, {
    ['shop.openingHours']: vendor.shop.openingHours,
  });
};

export const updateVendorAddress = async (
  vendorID: string,
  address: DemmiFS.VendorAddress,
): Promise<void> => {
  Logger({ objs: { vendorID, address } }, updateVendorAddress);
  const docRef = doc(FSCollections.Vendors, vendorID);
  return updateDoc(docRef, {
    address: {
      geo: address.geo,
      countryCode: address.countryCode,
      address: address.address,
    },
  });
};

export const checkVendorImageExists = async (
  vendorDocID: string,
  imageURL: string,
) => {
  const pathReference = ref(
    storage,
    `/${vendorDocID}/${StoragePaths.VENDOR_IMAGES}/${imageURL}`,
  );
  return getDownloadURL(pathReference)
    .then(_ => true)
    .catch(_ => false);
};

export const updateVendorImages = async (
  id: string,
  images: DemmiFS.DemmiImageResource[],
): Promise<void> => {
  Logger({ objs: { id, images } }, updateVendorImages);
  const docRef = doc(FSCollections.Vendors, id);
  return updateDoc(docRef, {
    images,
  });
};
