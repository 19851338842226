import { Timestamp } from 'firebase/firestore';
import React, { useState } from 'react';

import { updateOrderReviewResponse } from '@core/services/networkService/orders/orders';
import DemmiButton from '@demmi-ui/Button';
import DemmiIconButton from '@demmi-ui/IconButton';
import DemmiTextArea from '@demmi-ui/TextArea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS } from '@subhanhabib/demmilib';
import { faStar } from '@fortawesome/pro-light-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import { faCancel, faEdit, faSave } from '@fortawesome/pro-regular-svg-icons';

interface Props {
  CSSBlock: string;
  review?: DemmiFS.VendorReview;
  vendorID: string;
}

const OrderItemTabReview: React.FC<Props> = ({
  CSSBlock,
  review,
  vendorID,
}) => {
  const [response, setResponse] = useState<string>(
    review?.response ? review.response.body.join('\n') : '',
  );
  const [editMode, setEditMode] = useState(false);

  const updateResponse = (reviewID: string, response: string) => {
    const res: DemmiFS.FSVendorReviewResponse = {
      body: response.split('\n'),
      timestamp: Timestamp.now(),
    };
    demmiRequest(updateOrderReviewResponse(vendorID, reviewID, res)).then(
      () => {},
    );
  };

  return review ? (
    <div className={`${CSSBlock}__review`}>
      <div className={`${CSSBlock}__review-timestamp`}>
        Reviewed on:
        <span>
          {DemmiFS.getDateFromFirestore(review.timestamp)?.toDateString()}
        </span>
      </div>
      <div className={`${CSSBlock}__review-title`}>{review.title}</div>
      <div className={`${CSSBlock}__review-description`}>
        {review.body.map((paragraph, i) => (
          <div className={`${CSSBlock}__review-description-paragraph`} key={i}>
            {paragraph}
          </div>
        ))}
      </div>
      <div className={`${CSSBlock}__review-rating`}>
        {[...Array(5)].map((_, i) => (
          <FontAwesomeIcon
            icon={review!.rating < i ? faStar : faStarSolid}
            key={i}
          />
        ))}
      </div>
      <div className={`${CSSBlock}__review-response`}>
        {editMode ? (
          <>
            <div className={`${CSSBlock}__review-response-title`}>
              Your response
              <div className={`${CSSBlock}__review-response-title-buttons`}>
                <DemmiIconButton
                  variant="delete"
                  icon={faCancel}
                  onClick={() => {
                    setResponse(review.response?.body.join('\n') ?? '');
                    setEditMode(false);
                  }}
                />
                <DemmiIconButton
                  variant="save"
                  icon={faSave}
                  onClick={() => {
                    updateResponse(review.docID, response);
                    setEditMode(false);
                  }}
                />
              </div>
            </div>
            <DemmiTextArea
              placeholder="Your response"
              value={response}
              maxLength={1000}
              maxHeight={24}
              onChange={val => setResponse(val)}
            />
          </>
        ) : review.response ? (
          <>
            <div className={`${CSSBlock}__review-response-title`}>
              Your response
              <DemmiIconButton
                icon={faEdit}
                onClick={() => setEditMode(!editMode)}
              />
            </div>
            {review.response.body.map((paragraph, i) => (
              <div
                className={`${CSSBlock}__review-description-paragraph`}
                key={i}>
                {paragraph}
              </div>
            ))}
          </>
        ) : (
          <div className={`${CSSBlock}__review-no-response`}>
            You&apos;ve not yet responded to this review.
            <DemmiButton
              text="Respond now"
              faIcon="fa-edit"
              onClick={() => setEditMode(true)}
            />
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className={`${CSSBlock}__review-none`}>
      Not yet reviewed by customer
    </div>
  );
};

export default OrderItemTabReview;
