import {
  QueryDocumentSnapshot,
  Unsubscribe,
  addDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';
import { DemmiFS, DemmiLogType, Logger } from '@subhanhabib/demmilib';

import { FSSubCollectionNames } from '../networkService';
import {
  parseToDemmiSupportChat,
  parseToDemmiSupportMessage,
  sortSupportChats,
} from './_helper';
import { demmiSupportChatQuery, demmiSupportQuery } from './_queries';

export const listenToDemmiSupport = async (
  vendorID: string,
  callback: (requests: DemmiFS.DemmiSupport[]) => void,
): Promise<Unsubscribe> => {
  Logger({ objs: { vendorID } }, listenToDemmiSupport);
  return onSnapshot(demmiSupportQuery(vendorID), async querySnapshot => {
    const chats: DemmiFS.DemmiSupport[] = [];
    const messageRequests: {
      [key: string]: Promise<DemmiFS.DemmiSupportMessage | undefined>;
    } = {};
    querySnapshot.forEach(
      async (doc: QueryDocumentSnapshot<DemmiFS.FSDemmiSupport>) => {
        messageRequests[doc.id] = getLastDemmiSupportMessage(doc.id);
      },
    );
    const messages = await Promise.all(Object.values(messageRequests));
    querySnapshot.forEach(
      async (doc: QueryDocumentSnapshot<DemmiFS.FSDemmiSupport>) => {
        const lastMessage =
          messages[Object.keys(messageRequests).indexOf(doc.id)];

        chats.push({
          lastMessage,
          ...parseToDemmiSupportChat(doc),
        });
      },
    );
    callback(sortSupportChats(chats));
  });
};

export const listenToDemmiSupportChatMessages = async (
  chatID: string,
  callback: (requests: DemmiFS.DemmiSupportMessage[]) => void,
): Promise<Unsubscribe> => {
  Logger({ objs: { chatID } }, listenToDemmiSupportChatMessages);
  return onSnapshot(demmiSupportChatQuery(chatID), querySnapshot => {
    const messages: DemmiFS.DemmiSupportMessage[] = [];
    querySnapshot.forEach(
      (doc: QueryDocumentSnapshot<DemmiFS.FSDemmiSupportMessage>) => {
        const m = parseToDemmiSupportMessage(doc);
        if (m.timestamp) messages.push(m);
      },
    );
    callback(messages.sort((a, b) => (a.timestamp! > b.timestamp! ? 1 : -1)));
  });
};

export const getLastDemmiSupportMessage = async (
  chatID: string,
): Promise<DemmiFS.DemmiSupportMessage | undefined> => {
  Logger({ objs: { chatID } }, getLastDemmiSupportMessage);
  const q = query(
    FSCollections.DemmiSupportMessages([
      chatID,
      FSSubCollectionNames.DEMMI_SUPPORT_MESSAGES,
    ]),
    orderBy('timestamp', 'desc'),
    limit(1),
  );

  const querySnapshot = await getDocs(q).catch(e => {
    Logger(
      { objs: { chatID, e }, type: DemmiLogType.error },
      getLastDemmiSupportMessage,
    );
  });
  if (!querySnapshot || querySnapshot.empty) return undefined;
  return parseToDemmiSupportMessage(querySnapshot.docs[0]);
};

export const getDemmiSupportChat = async (
  chatID: string,
): Promise<DemmiFS.DemmiSupport | undefined> => {
  Logger({ objs: { chatID } }, getDemmiSupportChat);
  const docSnap = await getDoc(doc(FSCollections.DemmiSupport, chatID));
  if (docSnap.exists()) {
    return parseToDemmiSupportChat(docSnap);
  } else {
    Logger(
      { messages: ['No such document!'], type: DemmiLogType.error },
      getDemmiSupportChat,
    );
  }
  return undefined;
};

export const sendDemmiSupportMessage = async (
  chatID: string,
  message: DemmiFS.FSDemmiSupportMessage,
): Promise<string> => {
  Logger({ objs: { chatID, message } }, sendDemmiSupportMessage);
  const docRef = await addDoc(
    FSCollections.DemmiSupportMessages([
      chatID,
      FSSubCollectionNames.DEMMI_SUPPORT_MESSAGES,
    ]),
    message,
  );
  return docRef.id;
};

export const createDemmiSupportChat = async (
  chat: DemmiFS.FSDemmiSupport,
): Promise<string> => {
  Logger({ objs: { chat } }, createDemmiSupportChat);
  const docRef = await addDoc(FSCollections.DemmiSupport, chat);
  return docRef.id;
};
