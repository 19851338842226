import { QueryDocumentSnapshot } from 'firebase/firestore';

import { DemmiFS } from '@subhanhabib/demmilib';

export const parseToRefundRequest = (
  doc: QueryDocumentSnapshot<DemmiFS.FSRefundRequest>
): DemmiFS.RefundRequest => {
  const type = DemmiFS.RefundHelper.getRefundType(doc.data().type);

  return {
    docID: doc.id,
    ...(doc.data() as DemmiFS.FSRefundRequest),
    type,
    updates: doc.data().updates.map(update => ({
      ...update,
      statusUpdate: DemmiFS.RefundHelper.getRefundStatus(update.statusUpdate),
      updatedBy: DemmiFS.RefundHelper.getUpdatedBy(update.updatedBy),
    })),
  };
};
