import React, { useEffect, useState } from 'react';

import { DemmiHelpers } from '@subhanhabib/demmilib';

import InputTimeDropdown from './InputTimeDropdown';

interface Props {
  value: number;
  onChange: (val: number) => void;
}

const timesH = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];
const timesM = [0, 15, 30, 45];

const InputTime: React.FC<Props> = ({ value, onChange }) => {
  const CSSBlock = 'demmi-ui-input-time';
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);

  useEffect(() => {
    setHour(DemmiHelpers.DemmiTime.timeToHour(value));
    setMinute(DemmiHelpers.DemmiTime.timeToMinute(value));
  }, [value]);

  return (
    <div>
      <div className={`${CSSBlock}`}>
        <InputTimeDropdown
          times={timesH}
          triggerEl={
            <button className={`${CSSBlock}__button`}>
              {DemmiHelpers.DemmiTime.formateTimeSegment(hour)}
            </button>
          }
          onClick={v => {
            setHour(v);
            onChange(v * 60 + minute);
          }}
        />
        <span>:</span>
        <InputTimeDropdown
          times={timesM}
          triggerEl={
            <button className={`${CSSBlock}__button`}>
              {DemmiHelpers.DemmiTime.formateTimeSegment(minute)}
            </button>
          }
          onClick={v => {
            setMinute(v);
            onChange(hour * 60 + v);
          }}
        />
      </div>
    </div>
  );
};

export default InputTime;
