import React, { useEffect, useState } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { getOrders } from '@core/services/networkService/orders/orders';
import { selectVendor } from '@core/store/appSlice/appSlice';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

import OrdersList from './OrdersList';

const OrdersCompleted: React.FC = () => {
  const vendor = useAppSelector(selectVendor);
  const [orders, setOrders] = useState<DemmiFS.Order[]>([]);

  useEffect(() => {
    if (vendor) {
      demmiRequest(getOrders(vendor.docID)).then(o => {
        const completedOrders = DemmiFS.OrderHelper.getCompletedOrders(
          vendor.docID,
          o,
        );
        setOrders(DemmiFS.OrderHelper.sortByCollectionDate(completedOrders));
      });
    }
  }, [vendor]);

  const onClickRefresh = () => {
    if (vendor?.docID)
      demmiRequest(getOrders(vendor.docID)).then(o => {
        const completedOrders = DemmiFS.OrderHelper.getCompletedOrders(
          vendor.docID,
          o,
        );
        setOrders(DemmiFS.OrderHelper.sortByCollectionDate(completedOrders));
      });
  };

  return <OrdersList orders={orders} onClickRefresh={onClickRefresh} />;
};

export default OrdersCompleted;
