import React from 'react';

import { DemmiFS } from '@subhanhabib/demmilib';

import OrderItemTabRefundCost from './_tab-refund-cost';
import OrderItemTabRefundProducts from './_tab-refund-products';

interface Props {
  CSSBlock: string;
  refundRequest: DemmiFS.RefundRequest;
  order: DemmiFS.Order;
}

const OrderItemTabRefundDetails: React.FC<Props> = ({
  CSSBlock,
  refundRequest,
  order,
}) => {
  return (
    <div className={`${CSSBlock}__details`}>
      <div className={`${CSSBlock}__details-title`}>Refund details</div>
      {!!refundRequest.productIDs && refundRequest.productIDs.length > 0 && (
        <OrderItemTabRefundProducts
          CSSBlock={CSSBlock}
          productIDs={refundRequest.productIDs}
          order={order}
        />
      )}
      <OrderItemTabRefundCost
        CSSBlock={CSSBlock}
        productIDs={refundRequest.productIDs ?? []}
        refundRequest={refundRequest}
      />
    </div>
  );
};

export default OrderItemTabRefundDetails;
