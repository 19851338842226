import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import DemmiIconButton, { IconButtonSize } from '@demmi-ui/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DemmiHooks, Logger } from '@subhanhabib/demmilib';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';

export enum DemmiToastType {
  normal,
  warning,
  error,
  success,
}

interface DemmiToastPropertiesActionable extends DemmiToastPropertiesBase {
  actionButtonText: string;
  actionButtonOnClick: () => void;
}

export interface DemmiToastPropertiesBase {
  id: string;
  type?: DemmiToastType;
  title: string;
  body: string;
  onDismissed?: () => void;
  autoDismiss?: boolean;
}

export type DemmiToastProperties =
  | DemmiToastPropertiesBase
  | DemmiToastPropertiesActionable;

// const isActionable = (
//   props: DemmiToastProperties,
// ): props is DemmiToastPropertiesActionable => {
//   return 'actionButtonText' in props;
// };

const DURATION_TIMEOUT = 5000;
const DURATION_INTERVAL = 100;

const DURATION_SLIDE_OUT = 500 + 50;
const DURATION_MAX_HEIGHT = 100 + 50;

const DemmiToast: React.FC<DemmiToastProperties> = props => {
  const CSSBlock = 'demmi-ui-toast';
  const {
    onDismissed,
    id,
    type = DemmiToastType.normal,
    title,
    body,
    autoDismiss = true,
  } = props;
  // const actions = isActionable(props) ? props : undefined;
  const [remainingPercentage, setRemainingPercentage] = useState<number>(100);

  // 0: nothing, 1: dismissing (slide out), 2: dismissed (reduce max-height), 3: complete (call onDismissed())
  const [isDismissing, setIsDismissing] = useState(0);

  const { width: windowWidth } = DemmiHooks.useWindowSize();
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(0);

  useLayoutEffect(() => {
    if (contentRef.current && contentRef.current.clientHeight) {
      const height = contentRef.current.clientHeight;
      setContentHeight(height);
    }
  }, [windowWidth, contentRef, contentRef.current]);

  useEffect(() => {
    Logger(
      { messages: ['isDismissing', 'useEffect'], objs: { isDismissing, id } },
      DemmiToast,
    );
    let timeout: NodeJS.Timeout;
    // if (autoDismiss)
    switch (isDismissing) {
      case 3:
        if (onDismissed) onDismissed();
        Logger(
          {
            messages: ['if (onDismissed) onDismissed()'],
            objs: { id },
          },
          DemmiToast,
        );
        break;
      case 2:
        timeout = setTimeout(() => {
          setIsDismissing(3);
        }, DURATION_MAX_HEIGHT);
        break;
      case 1:
        timeout = setTimeout(() => {
          setIsDismissing(2);
        }, DURATION_SLIDE_OUT);
        break;
      default:
        break;
    }
    return () => clearTimeout(timeout);
  }, [isDismissing]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (autoDismiss) setIsDismissing(1);
    }, DURATION_TIMEOUT);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    setRemainingPercentage(100);
    let loop = 0;
    let interval: NodeJS.Timeout;

    if (autoDismiss)
      interval = setInterval(() => {
        setRemainingPercentage(
          ((DURATION_TIMEOUT - loop * DURATION_INTERVAL) / DURATION_TIMEOUT) *
            100,
        );
        loop++;
      }, DURATION_INTERVAL);

    return () => clearInterval(interval);
  }, []);

  const onClickDismiss = () => {
    Logger({ messages: ['onClickDismiss'], objs: { body } }, DemmiToast);
    setIsDismissing(1);
  };

  return (
    <div
      ref={contentRef}
      className={`${CSSBlock} ${CSSBlock}--${type} ${
        isDismissing > 0 ? `${CSSBlock}--dismissing-${isDismissing}` : ''
      } ${
        isDismissing === 2 || isDismissing === 3 ? `${CSSBlock}--dismissed` : ''
      }`}
      style={{
        maxHeight:
          isDismissing === 0
            ? 'unset'
            : isDismissing === 2 || isDismissing === 3
              ? 0
              : `${contentHeight}px`,
      }}>
      {autoDismiss && (
        <div
          className={`${CSSBlock}__timer`}
          style={{ maxWidth: `${remainingPercentage}%` }}></div>
      )}
      <div className={`${CSSBlock}__content`}>
        <div className={`${CSSBlock}__icon`}>
          <FontAwesomeIcon icon={faCircleInfo} />
        </div>
        <div className={`${CSSBlock}__text-content`}>
          <div className={`${CSSBlock}__title`}>
            {title} {isDismissing}
          </div>
          <div className={`${CSSBlock}__body`}>
            <ReactMarkdown
              className={`${CSSBlock}__body`}
              components={{ p: 'div' }}>
              {body}
            </ReactMarkdown>
          </div>
        </div>
        <div className={`${CSSBlock}__dismiss-area`}>
          {/* {actions && (
          <DemmiButton
            text={actions.actionButtonText}
            size={DemmiButtonSize.S}
            faIcon="fa-arrow-right-to-bracket"
            onClick={actions.actionButtonOnClick}
          />
        )} */}
          {autoDismiss === false && (
            <DemmiIconButton
              faIcon="fa-xmark"
              onClick={() => onClickDismiss()}
              size={IconButtonSize.SMALL}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DemmiToast;
