import React, { useEffect, useState } from 'react';

import DemmiCard from '@demmi-ui/Card/Card';
import DemmiIconButton, { IconButtonSize } from '@demmi-ui/IconButton';
import DemmiInput from '@demmi-ui/Input';
import { DemmiFS } from '@subhanhabib/demmilib';

import ReviewsSidebarButton from './ReviewsSidebarButton';

interface Props {
  selectedReview?: DemmiFS.VendorReview;
  onClick: (review: DemmiFS.VendorReview) => void;
  onClickRefresh: () => void;
  reviews: DemmiFS.VendorReview[];
}

const ReviewsSidebar: React.FC<Props> = ({
  selectedReview,

  onClickRefresh,
  reviews,
  onClick,
}) => {
  const CSSBlock = 'sidebar';

  const [filteredReviews, setFilteredReviews] = useState<
    DemmiFS.VendorReview[]
  >([]);

  useEffect(() => {
    filterReviews();
  }, [reviews]);

  const filterReviews = (filter?: string) => {
    const organised = reviews.reduce(
      (acc: DemmiFS.VendorReview[], cur: DemmiFS.VendorReview) => {
        if (filter && !cur.title.toLowerCase().includes(filter.toLowerCase()))
          return acc;
        else return [...acc, cur];
      },
      []
    );
    setFilteredReviews(organised);
  };
  return (
    <div className={`${CSSBlock} ${CSSBlock}--reviews`}>
      <DemmiCard darkOnly className={`${CSSBlock}__header`}>
        <div className={`${CSSBlock}__title`}>
          Reviews
          <div className={`${CSSBlock}__title-buttons`}>
            <DemmiIconButton
              faIcon={'fa-rotate-right'}
              onClick={onClickRefresh}
              size={IconButtonSize.SMALL}
            />
          </div>
        </div>
        <div className={`${CSSBlock}__search`}>
          <DemmiInput
            onChangeValue={val => filterReviews(val)}
            invert
            type="text"
            placeholder="Filter reviews by title"
          />
        </div>
      </DemmiCard>
      <div className={`${CSSBlock}__content`}>
        {filteredReviews.map((review, i) => (
          <ReviewsSidebarButton
            key={i}
            review={review}
            onClick={() => onClick(review)}
            active={selectedReview?.docID === review.docID}
          />
        ))}
      </div>
    </div>
  );
};

export default ReviewsSidebar;
