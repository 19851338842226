import {
  QueryDocumentSnapshot,
  Unsubscribe,
  arrayUnion,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  updateDoc,
} from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';
import { DemmiFS, DemmiLogType, Logger } from '@subhanhabib/demmilib';

import { parseToRefundRequest } from './_helper';
import {
  hasRefundRequestQuery,
  refundRequestQuery,
  refundRequestsQuery,
} from './_queries';

export const listenToRefundRequests = async (
  vendorID: string,
  callback: (requests: DemmiFS.RefundRequest[]) => void,
): Promise<Unsubscribe> => {
  Logger({ objs: { vendorID } }, listenToRefundRequests);
  return onSnapshot(refundRequestsQuery(vendorID), querySnapshot => {
    const requests: DemmiFS.RefundRequest[] = [];
    querySnapshot.forEach(
      (doc: QueryDocumentSnapshot<DemmiFS.FSRefundRequest>) => {
        requests.push(parseToRefundRequest(doc));
      },
    );
    callback(requests);
  });
};

export const listenToRefundRequest = async (
  requestID: string,
  callback: (requests?: DemmiFS.RefundRequest) => void,
): Promise<Unsubscribe> => {
  Logger({ objs: { requestID } }, listenToRefundRequest);
  return onSnapshot(refundRequestQuery(requestID), querySnapshot => {
    callback(
      querySnapshot.exists() ? parseToRefundRequest(querySnapshot) : undefined,
    );
  });
};

export const hasRefundRequest = async (
  orderDocID: string,
  vendorID: string,
): Promise<string | undefined> => {
  Logger({ objs: { orderDocID, vendorID } }, hasRefundRequest);
  const docSnap = await getDocs(hasRefundRequestQuery(orderDocID, vendorID));
  return docSnap.empty ? undefined : docSnap.docs[0].id;
};

export const updateRefundRequest = async (
  requestID: string,
  update: DemmiFS.RefundRequestUpdate,
): Promise<void> => {
  Logger({ objs: { requestID, update } }, updateRefundRequest);
  const request = await getDoc(refundRequestQuery(requestID));
  if (!request) {
    Logger(
      {
        messages: ['Failed to find refund request to push update.'],
        objs: { requestID, update },
        type: DemmiLogType.error,
      },
      updateRefundRequest,
    );
    return;
  }

  const docRef = doc(FSCollections.RefundRequests, requestID);
  return updateDoc(docRef, {
    updates: arrayUnion(update),
  });
};
