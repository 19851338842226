import {
  QueryDocumentSnapshot,
  Unsubscribe,
  onSnapshot,
} from 'firebase/firestore';
import { DateTime } from 'luxon';

import { AnalyticsTimeFrames } from '@helpers/analytics.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

import { ordersVendorQuery } from './_queries';

const getStartDate = (timeframe: AnalyticsTimeFrames) => {
  return timeframe === AnalyticsTimeFrames.THIS_WEEK
    ? DateTime.now().startOf('day').minus({ days: 7 })
    : timeframe === AnalyticsTimeFrames.LAST_7_DAYS
    ? DateTime.now().startOf('week')
    : timeframe === AnalyticsTimeFrames.THIS_MONTH
    ? DateTime.now().startOf('day').minus({ months: 1 })
    : DateTime.now();
};

export const listenToTotalOrderCount = async (
  vendorID: string,
  timeframe: AnalyticsTimeFrames,
  callback: (count: number) => void
): Promise<Unsubscribe> => {
  const startDate = getStartDate(timeframe);
  return onSnapshot(ordersVendorQuery(vendorID), querySnapshot => {
    let count = 0;
    querySnapshot.forEach((doc: QueryDocumentSnapshot<DemmiFS.FSOrder>) => {
      const date = DemmiFS.getDateFromFirestore(doc.data()['orderDate']);
      if (date && date >= startDate.toJSDate()) count++;
    });
    callback(count);
  });
};

export const listenToTotalProductsSold = async (
  vendorID: string,
  timeframe: AnalyticsTimeFrames,
  callback: (count: number) => void
): Promise<Unsubscribe> => {
  const startDate = getStartDate(timeframe);
  return onSnapshot(ordersVendorQuery(vendorID), querySnapshot => {
    let count = 0;
    querySnapshot.forEach((doc: QueryDocumentSnapshot<DemmiFS.FSOrder>) => {
      const date = DemmiFS.getDateFromFirestore(doc.data()['orderDate']);
      if (date && date >= startDate.toJSDate()) {
        const vendors = doc.data()['vendors'];
        if (vendors) {
          const vendor = vendors[vendorID];
          if (vendorID) {
            count += vendor['products'].length;
          }
        }
      }
    });
    callback(count);
  });
};
