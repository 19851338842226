import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from '@core/hooks/appHooks';
import { getAllReviews } from '@core/services/networkService/reviews/reviews';
import { SearchParams } from '@core/services/routingService';
import { selectVendor } from '@core/store/appSlice/appSlice';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

import ReviewsSidebar from './ReviewsSidebar';
import ReviewItem from './reviewItem/ReviewItem';

const Reviews: React.FC = () => {
  const CSSBlock = 'reviews';
  const [searchParams, setSearchParams] = useSearchParams();
  const [reviews, setReviews] = useState<DemmiFS.VendorReview[]>([]);
  const vendor = useAppSelector(selectVendor);
  const [selectedReview, setSelectedReview] = useState<DemmiFS.VendorReview>();

  useEffect(() => {
    if (vendor)
      demmiRequest(getAllReviews(vendor!.docID)).then(o => {
        setReviews(o);
        if (searchParams.get(SearchParams.REVIEW_ID)) {
          setSelectedReview(
            o.find(c => c.docID === searchParams.get(SearchParams.REVIEW_ID)),
          );
        } else {
          setSelectedReview(undefined);
        }
      });
  }, [vendor]);

  const onClickRefresh = () => {
    if (vendor)
      demmiRequest(getAllReviews(vendor!.docID)).then(o => setReviews(o));
  };

  const onUpdateReviewResponse = (
    reviewID: string,
    response: DemmiFS.FSVendorReviewResponse,
  ) => {
    const i = reviews.findIndex(r => r.docID === reviewID);
    if (i > -1) {
      setReviews([
        ...reviews.slice(0, i),
        { ...reviews[i], response },
        ...reviews.slice(i + 1),
      ]);
    }
  };

  const onClickReview = (review: DemmiFS.VendorReview) => {
    setSearchParams({ [SearchParams.REVIEW_ID]: review.docID });
    setSelectedReview(review);
  };

  return (
    <div className={`${CSSBlock}`}>
      <ReviewsSidebar
        selectedReview={selectedReview}
        onClick={onClickReview}
        onClickRefresh={onClickRefresh}
        reviews={reviews}
      />
      {vendor && selectedReview && (
        <ReviewItem
          review={selectedReview}
          vendor={vendor}
          onUpdateReviewResponse={onUpdateReviewResponse}
        />
      )}
    </div>
  );
};

export default Reviews;
