import { QueryDocumentSnapshot } from 'firebase/firestore';

import { DemmiFS, DemmiHelpers } from '@subhanhabib/demmilib';

export const parseToVendorReview = (
  vendorID: string,
  doc: QueryDocumentSnapshot<DemmiFS.FSVendorReview>,
  customer: DemmiFS.FSUser,
): DemmiFS.VendorReview => {
  const review = doc.data();
  return {
    ...review,
    docID: doc.id,

    customer,
    vendorID,
  };
};

export const getReviewCustomerFromRequests = (
  customerRequests: {
    [key: string]: Promise<DemmiFS.FSUser | undefined>;
  },
  customers: (DemmiFS.FSUser | undefined)[],
  customerID: string,
): DemmiFS.FSUser => {
  const customerIndex = Object.keys(customerRequests).indexOf(customerID);
  return customers[customerIndex]
    ? customers[customerIndex]!
    : DemmiHelpers.Customer.EMPTY_CUSTOMER;
};
