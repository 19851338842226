import { checkProductImageExists } from '@core/services/networkService/functions/_product';
import { DemmiLogType, Logger } from '@subhanhabib/demmilib';

export const checkVendorImageExistsWithRetry = async (
  vendorID: string,
  imageResource: string
): Promise<boolean> => {
  for (let attempt = 0; attempt < 5; attempt++) {
    const exists = await checkProductImageExists(vendorID, imageResource);
    if (exists) return true;
    await new Promise(resolve => setTimeout(resolve, 1000));
  }
  Logger(
    {
      messages: ['Image failed to be found after 5 attempts'],
      objs: { vendorID, imageResource },
      type: DemmiLogType.error,
    },
    checkVendorImageExistsWithRetry
  );
  return false;
};
