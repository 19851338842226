import React from 'react';

import DemmiCardDivider from '@demmi-ui/Card/CardDivider';
import DemmiInput from '@demmi-ui/Input';
import Menu from '@demmi-ui/Menu/Menu';
import MenuTriggerButton from '@demmi-ui/Menu/MenuTriggerButton';
import { MenuItem } from '@demmi-ui/Menu/MenuTypes';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  currencyCode: string;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  minQuantity: number;
  setMinQuantity: React.Dispatch<React.SetStateAction<number>>;
  availableQuantity: number;
  setAvailableQuantity: React.Dispatch<React.SetStateAction<number>>;
  pricePerUnit: number;
  setPricePerUnit: React.Dispatch<React.SetStateAction<number>>;
  increments: number;
  setIncrements: React.Dispatch<React.SetStateAction<number>>;
  unit: DemmiFS.UnitsHelper.WeightUnit;
  setUnit: React.Dispatch<React.SetStateAction<DemmiFS.UnitsHelper.WeightUnit>>;

  // if unit === "weight_each"
  weightPerItem: number;
  setWeightPerItem: React.Dispatch<React.SetStateAction<number>>;
  unitPerItem: DemmiFS.UnitsHelper.WeightUnit;
  setUnitPerItem: React.Dispatch<
    React.SetStateAction<DemmiFS.UnitsHelper.WeightUnit>
  >;
}

const ProductVariantInputs: React.FC<Props> = ({
  currencyCode,
  name,
  setName,
  minQuantity,
  setMinQuantity,
  availableQuantity,
  setAvailableQuantity,
  pricePerUnit,
  setPricePerUnit,
  increments,
  setIncrements,
  unit,
  setUnit,
  weightPerItem,
  setWeightPerItem,
  unitPerItem,
  setUnitPerItem,
}) => {
  const CSSBlock = 'product-variant-inputs';

  const weightToMenuItem = (
    val: string,
    unit: DemmiFS.UnitsHelper.WeightUnit
  ): MenuItem<DemmiFS.UnitsHelper.WeightUnit> => {
    return {
      id: val,
      text: DemmiFS.UnitsHelper.getWeightUnitText(unit),
      item: unit,
    };
  };

  const getMenuContent = (units: DemmiFS.UnitsHelper.WeightUnit[]) => {
    return {
      sections: [
        {
          items: Object.entries(units).map(([val, unit]) =>
            weightToMenuItem(val, unit)
          ),
        },
      ],
    };
  };

  return (
    <div className={`${CSSBlock}`}>
      <DemmiInput
        type="text"
        placeholder="Variant Name"
        value={name}
        onChangeValue={val => setName(val)}
      />
      <DemmiCardDivider />
      <div className={`${CSSBlock}__details-row`}>
        <div className={`${CSSBlock}__details-item`}>
          <div className={`${CSSBlock}__details-title`}>
            Available
            <br />
            Quantity
          </div>
          <DemmiInput
            type="number"
            placeholder="Available Quantity"
            value={availableQuantity.toString()}
            onChangeValue={val => setAvailableQuantity(parseInt(val))}
          />
        </div>
        <div className={`${CSSBlock}__details-item`}>
          <div className={`${CSSBlock}__details-title`}>
            Minimum
            <br />
            Quantity
          </div>
          <DemmiInput
            type="number"
            placeholder="Minimum Quantity"
            value={minQuantity.toString()}
            onChangeValue={val => setMinQuantity(parseInt(val))}
          />
        </div>
        <div className={`${CSSBlock}__details-item`}>
          <div className={`${CSSBlock}__details-title`}>
            Quantity
            <br />
            Increments
          </div>
          <DemmiInput
            type="number"
            placeholder="Quantity Increments"
            value={increments.toString()}
            onChangeValue={val => setIncrements(parseFloat(val))}
          />
        </div>
      </div>
      <DemmiCardDivider />
      <div className={`${CSSBlock}__details-row`}>
        <div className={`${CSSBlock}__details-item`}>
          <div className={`${CSSBlock}__details-title`}>
            Price Per Unit ({currencyCode})
          </div>
          <DemmiInput
            type="number"
            placeholder="Price Per Unit"
            value={pricePerUnit.toString()}
            onChangeValue={val => setPricePerUnit(parseFloat(val))}
          />
        </div>
        <div className={`${CSSBlock}__details-item`}>
          <div className={`${CSSBlock}__details-title`}>Unit</div>
          <Menu<DemmiFS.UnitsHelper.WeightUnit>
            content={getMenuContent(
              Object.values(DemmiFS.UnitsHelper.WeightUnit).filter(
                w => w !== DemmiFS.UnitsHelper.WeightUnit.undefined
              )
            )}
            triggerEl={
              <MenuTriggerButton
                text={DemmiFS.UnitsHelper.getWeightUnitText(unit)}
              />
            }
            onClick={id => setUnit(id)}
          />
        </div>
      </div>
      {unit === DemmiFS.UnitsHelper.WeightUnit.each && (
        <div className={`${CSSBlock}__details-row`}>
          <div className={`${CSSBlock}__details-item`}>
            <div className={`${CSSBlock}__details-title`}>Weight Per Item</div>
            <DemmiInput
              type="number"
              placeholder="e.g. 85"
              value={weightPerItem.toString()}
              onChangeValue={val => setWeightPerItem(parseFloat(val))}
            />
          </div>
          <div className={`${CSSBlock}__details-item`}>
            <div className={`${CSSBlock}__details-title`}>Unit Per Item</div>
            <Menu<DemmiFS.UnitsHelper.WeightUnit>
              content={getMenuContent(
                Object.values(DemmiFS.UnitsHelper.WeightUnit).filter(
                  w =>
                    w !== DemmiFS.UnitsHelper.WeightUnit.each &&
                    w !== DemmiFS.UnitsHelper.WeightUnit.undefined
                )
              )}
              triggerEl={
                <MenuTriggerButton
                  text={
                    unitPerItem
                      ? DemmiFS.UnitsHelper.getWeightUnitText(unitPerItem)
                      : '-'
                  }
                />
              }
              onClick={id => setUnitPerItem(id)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductVariantInputs;
