import React, { useEffect, useState } from 'react';

import LoadingGlobal from '@components/loadingIntro/LoadingGlobal';
import DemmiButton from '@demmi-ui/Button';
import { useAuth } from '@providers/authProvider';
import { DemmiHooks, Logger } from '@subhanhabib/demmilib';

import LoginCard from './LoginCard';
import RegisterCard from './RegisterCard';
import SignInError from './SignInError';

const Login: React.FC = () => {
  const CSSBlock = 'login';

  const { signIn } = useAuth();
  const [isVisible, setIsVisible] = useState(false);
  const [isRegisterVisible, _] = useState(false);

  const [inputEmail, setInputEmail] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const [error, setError] = useState<string>();

  const onSubmit = () => {
    setIsLoading(true);
    signIn(inputEmail, inputPassword)
      .then(a => Logger({ objs: { inputEmail, a } }, onSubmit))
      .catch(e => {
        setError(e);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsVisible(true);
    return () => setIsVisible(false);
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [isGlobalLoadingMounted, setIsGlobalLoadingMounted] = useState(false);
  const shouldRenderGlobalLoading = DemmiHooks.useDelayUnmount(
    isGlobalLoadingMounted,
    200,
  );
  useEffect(() => {
    setIsGlobalLoadingMounted(isLoading);
  }, [isLoading]);

  return (
    <>
      <div
        className={`${CSSBlock}__wrapper ${
          !isVisible ? `${CSSBlock}--hide` : ''
        } ${error ? `${CSSBlock}--error` : ''} ${
          isRegisterVisible ? `${CSSBlock}--register-visible` : ''
        }`}>
        <div className={`${CSSBlock}__content-position`}>
          <span className={`${CSSBlock}__sign-in`}>
            <LoginCard
              inputEmail={inputEmail}
              setInputEmail={setInputEmail}
              inputPassword={inputPassword}
              setInputPassword={setInputPassword}
              onSubmit={onSubmit}
            />
            <SignInError error={error} />
          </span>
          <span className={`${CSSBlock}__register`}>
            <RegisterCard />
          </span>
        </div>
        <span className={`${CSSBlock}__submit-button`}>
          <DemmiButton
            onClick={() => {
              onSubmit();
              // setIsRegisterVisible(!isRegisterVisible);
            }}
            text={isRegisterVisible ? 'Register' : 'Sign In'}
          />
        </span>
      </div>

      {shouldRenderGlobalLoading && (
        <LoadingGlobal isMounted={isGlobalLoadingMounted} isLogin />
      )}
    </>
  );
};

export default Login;
