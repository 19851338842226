import { formatRelative } from 'date-fns';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import {
  updateNotificationDismissedStatus,
  updateNotificationReadStatus,
} from '@core/services/networkService/notifications/notifications';
import DemmiButton, { DemmiButtonSize } from '@demmi-ui/Button';
import DemmiIconButton, { IconButtonSize } from '@demmi-ui/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DemmiFS, DemmiHelpers } from '@subhanhabib/demmilib';

interface Props {
  notification: DemmiFS.NotificationVendor;
  CSSBlock: string;
  buttonText: string;
  buttonOnClick: () => void;
}

const NotificationItem: React.FC<Props> = ({
  notification,
  CSSBlock: itemCSSBlock,
  buttonText,
  buttonOnClick,
}) => {
  const CSSBlock = 'notification-item';
  const [readTimeout, setReadTimeout] = useState<NodeJS.Timeout>();

  const markAsDismissed = () =>
    updateNotificationDismissedStatus(notification.docID, true);
  const markAsRead = () =>
    updateNotificationReadStatus(notification.docID, true);
  const createHoverTimer = () =>
    setReadTimeout(setTimeout(() => markAsRead(), 2000));
  const clearHoverTimer = () => clearTimeout(readTimeout);

  useEffect(() => {
    return () => clearHoverTimer();
  }, []);

  return (
    <div
      className={`${itemCSSBlock} ${CSSBlock} ${
        notification.read ? '' : ` ${CSSBlock}--new`
      }`}
      onMouseEnter={() => createHoverTimer()}
      onMouseLeave={() => clearHoverTimer()}>
      <div className={`${CSSBlock}__badge`}>
        <FontAwesomeIcon
          icon={DemmiFS.NotificationHelper.getNotificationTypeIcon(
            notification.type,
          )}
        />
        {DemmiFS.NotificationHelper.getNotificationTypeText(notification.type)}
      </div>
      <div className={`${CSSBlock}__message`}>
        <ReactMarkdown
          className={`${CSSBlock}__text`}
          components={{ p: 'div' }}>
          {notification.message}
        </ReactMarkdown>
      </div>
      <div className={`${CSSBlock}__timestamp`}>
        {formatRelative(
          DemmiFS.getDateFromFirestore(notification.timestamp)!,
          new Date(),
          DemmiHelpers.Dates.formatRelativeOptions,
        )}
      </div>
      <div className={`${CSSBlock}__buttons`}>
        <DemmiButton
          size={DemmiButtonSize.S}
          faIcon="fa-arrow-right-to-bracket"
          text={buttonText}
          onClick={() => buttonOnClick()}
        />
        <DemmiIconButton
          faIcon="fa-xmark"
          onClick={() => markAsDismissed()}
          size={IconButtonSize.SMALL}
        />
      </div>
    </div>
  );
};

export default NotificationItem;
