import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import Sidebar from '@components/sidebar/Sidebar';
import { RoutingViews, getViewFromPath } from '@core/services/routingService';

const Orders: React.FC = () => {
  const CSSBlock = 'orders';
  const [activePage, setActivePage] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const view = getViewFromPath(location.pathname);
    setActivePage(
      view === RoutingViews.ORDERS_ALL
        ? 3
        : view === RoutingViews.ORDERS_COMPLETED
          ? 1
          : view === RoutingViews.ORDERS_REFUNDS
            ? 2
            : 0,
    );
  }, [location]);

  return (
    <div className={`${CSSBlock}`}>
      <Sidebar
        title="Orders"
        initialIndex={activePage}
        active={activePage}
        buttons={[
          {
            id: RoutingViews.ORDERS_INCOMING,
            text: 'Incoming Orders',
            faIcon: 'fa-regular fa-circle-down',
          },
          {
            id: RoutingViews.ORDERS_COMPLETED,
            text: 'Completed Orders',
            faIcon: 'fa-check',
          },
          {
            id: RoutingViews.ORDERS_REFUNDS,
            text: 'Orders with Refunds',
            faIcon: 'fa-regular fa-money-bill-transfer',
          },
          {
            id: RoutingViews.ORDERS_ALL,
            text: 'All Orders',
            faIcon: 'fa-list',
          },
        ]}
        onClick={id => navigate(id, { replace: true })}
      />
      <Outlet />
    </div>
  );
};

export default Orders;
