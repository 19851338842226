import { QueryDocumentSnapshot } from 'firebase/firestore';

import { DemmiFS } from '@subhanhabib/demmilib';

export const sortSupportChats = (chats: DemmiFS.DemmiSupport[]) => {
  return chats.sort((a, b) =>
    (a.lastMessage?.timestamp ?? a.createdAt) >
    (b.lastMessage?.timestamp ?? b.createdAt)
      ? -1
      : 1
  );
};

export const parseToDemmiSupportChat = (
  doc: QueryDocumentSnapshot<DemmiFS.FSDemmiSupport>
): DemmiFS.DemmiSupport => {
  return {
    docID: doc.id,
    ...(doc.data() as DemmiFS.FSDemmiSupport),
  };
};

export const parseToDemmiSupportMessage = (
  doc: QueryDocumentSnapshot<DemmiFS.FSDemmiSupportMessage>
): DemmiFS.DemmiSupportMessage => {
  return {
    docID: doc.id,
    ...(doc.data() as DemmiFS.FSDemmiSupportMessage),
  };
};
