import { useEffect, useRef } from 'react';

import ModalArea from '@demmi-ui/Modal/ModalArea';

import MenuItem from './MenuItem';
import { MenuContent, MenuSection } from './MenuTypes';

interface Props<T> {
  content: MenuContent<T>;
  onClick: (id: T) => void;
}

const MenuStatic = <T = string,>({
  content,
  onClick,
}: Props<T>): JSX.Element => {
  const CSSBlock = 'demmi-ui-menu';
  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const onKeyDown = (_: globalThis.KeyboardEvent) => {
      // if (e.key === 'Escape') onClose();
    };
    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  }, []);

  const contents = (sections: MenuSection<T>[]) =>
    sections.map((section, i) => (
      <ModalArea key={i} isDark={section.isDark}>
        {section.heading && (
          <div className={`${CSSBlock}__section-heading`}>
            {section.heading}
          </div>
        )}
        <div className={`${CSSBlock}__section-items`}>
          {section.items.map((item, j) => (
            <MenuItem key={j} item={item} onClick={() => onClick(item.item)} />
          ))}
        </div>
      </ModalArea>
    ));

  return (
    <div className={`${CSSBlock} ${CSSBlock}--static`} ref={menuRef}>
      <div className={`${CSSBlock}__card`}>
        <div>{contents(content.sections)}</div>
      </div>
    </div>
  );
};

export default MenuStatic;
