import { format } from 'date-fns';
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DemmiFS } from '@subhanhabib/demmilib';
import { faCheck, faCheckDouble } from '@fortawesome/pro-regular-svg-icons';

interface Props {
  message: DemmiFS.DemmiSupportMessage;
}

const SupportChatItem: React.FC<Props> = ({ message }) => {
  const CSSBlock = 'support-chat-item';

  return (
    <div
      className={`${CSSBlock} ${
        message.isSenderDemmi ? `${CSSBlock}--demmi` : ``
      }`}>
      <div className={`${CSSBlock}__text`}>{message.text}</div>
      <div className={`${CSSBlock}__timestamp`}>
        <FontAwesomeIcon
          size={'lg'}
          icon={message.read ? faCheckDouble : faCheck}
        />
        {message.timestamp
          ? format(
              DemmiFS.getDateFromFirestore(message.timestamp)!,
              'dd MMM yyyy, HH:mm:ss',
            )
          : '-'}
      </div>
    </div>
  );
};

export default SupportChatItem;
