import { format } from 'date-fns';
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DemmiFS } from '@subhanhabib/demmilib';
import { faCheckDouble } from '@fortawesome/pro-regular-svg-icons';

interface Props {
  CSSBlock: string;
  active: boolean;
  chat: DemmiFS.DemmiSupport;
  onClickChat: (chat: DemmiFS.DemmiSupport) => void;
  lastMessage?: DemmiFS.DemmiSupportMessage;
}

const DemmiSupportPanelListItem: React.FC<Props> = ({
  CSSBlock,
  active,
  chat,
  onClickChat,
  lastMessage,
}) => {
  return (
    <div
      className={`${CSSBlock}__item ${
        active ? `${CSSBlock}__item--active` : ``
      }`}
      onClick={() => onClickChat(chat)}>
      <div className={`${CSSBlock}__item-text`}>{chat.title}</div>
      <div className={`${CSSBlock}__item-message`}>
        <FontAwesomeIcon size={'lg'} icon={faCheckDouble} />
        {lastMessage ? lastMessage.text : '-'}
      </div>
      <div className={`${CSSBlock}__item-time`}>
        {lastMessage?.timestamp
          ? format(
              DemmiFS.getDateFromFirestore(lastMessage.timestamp)!,
              'HH:mm',
            )
          : '-'}
      </div>
    </div>
  );
};

export default DemmiSupportPanelListItem;
