import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SearchParams } from '@core/services/routingService';
import DemmiIconButton, { IconButtonSize } from '@demmi-ui/IconButton';
import Menu from '@demmi-ui/Menu/Menu';
import MenuTriggerButton from '@demmi-ui/Menu/MenuTriggerButton';
import { DemmiFS } from '@subhanhabib/demmilib';

import OrderListItem from './OrderListItem';
import OrderItem from './orderItem/OrderItem';

interface Props {
  orders: DemmiFS.Order[];
  onClickRefresh: () => void;
}

const OrdersList: React.FC<Props> = ({ orders, onClickRefresh }) => {
  const CSSBlock = 'orders-list';

  const [searchParams, setSearchParams] = useSearchParams();
  const [sortBy, setSortBy] = useState(
    DemmiFS.OrderHelper.SORT_BY.COLLECTION_TIME_ASC
  );
  const [sortedOrders, setSortedOrders] = useState(
    DemmiFS.OrderHelper.sortOrders(orders, sortBy)
  );
  const [selectedOrder, setSelectedOrder] = useState<
    DemmiFS.Order | undefined
  >();

  useEffect(() => {
    if (searchParams.get(SearchParams.ORDER_ID)) {
      setSelectedOrder(
        orders.find(o => o.docID === searchParams.get(SearchParams.ORDER_ID))
      );
    } else {
      setSelectedOrder(undefined);
    }
  }, [orders, searchParams]);

  useEffect(() => {
    setSortedOrders(DemmiFS.OrderHelper.sortOrders(orders, sortBy));
  }, [orders, sortBy]);

  const getMenuContent = () => {
    return {
      sections: [
        {
          items: (
            Object.values(
              DemmiFS.OrderHelper.SORT_BY
            ) as Array<DemmiFS.OrderHelper.SORT_BY>
          ).map(c => ({
            id: c,
            text: c,
            item: c,
          })),
        },
      ],
    };
  };

  const onClick = (order: DemmiFS.Order) => {
    setSearchParams({ [SearchParams.ORDER_ID]: order.docID });
    setSelectedOrder(order);
  };

  return (
    <>
      <div className={`${CSSBlock}`}>
        <div className={`${CSSBlock}__sort`}>
          <div className={`${CSSBlock}__sort-dropdown`}>
            <Menu<DemmiFS.OrderHelper.SORT_BY>
              content={getMenuContent()}
              triggerEl={<MenuTriggerButton title="Sort By" text={sortBy} />}
              onClick={sortBy => setSortBy(sortBy)}
            />
          </div>
          <DemmiIconButton
            faIcon={'fa-rotate-right'}
            onClick={onClickRefresh}
            size={IconButtonSize.LARGE}
          />
        </div>
        {sortedOrders.map((order, i) => (
          <OrderListItem
            active={selectedOrder?.docID === order.docID}
            order={order}
            key={i}
            onClick={onClick}
          />
        ))}
      </div>
      {selectedOrder && <OrderItem orderDocID={selectedOrder.docID} />}
    </>
  );
};

export default OrdersList;
