import { Unsubscribe } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import {
  listenToTotalOrderCount,
  listenToTotalProductsSold,
} from '@core/services/networkService/analytics/analytics';
import { selectVendor } from '@core/store/appSlice/appSlice';
import { AnalyticsTimeFrames } from '@helpers/analytics.helper';

import DashboardBadge from './DashboardBadge';

const Badges: React.FC = () => {
  const CSSBlock = 'dashboard-badges';
  const vendor = useAppSelector(selectVendor);

  const [orderCount, setOrderCount] = useState(0);
  const [productsSold, setProductsSold] = useState(0);

  const totalOrdersCallback = (count: number) => {
    setOrderCount(count);
  };
  const productsSoldCallback = (count: number) => {
    setProductsSold(count);
  };
  useEffect(() => {
    const snapshotListeners: Unsubscribe[] = [];
    if (vendor) {
      listenToTotalOrderCount(
        vendor.docID,
        AnalyticsTimeFrames.THIS_MONTH,
        totalOrdersCallback,
      ).then(u => snapshotListeners.push(u));
      listenToTotalProductsSold(
        vendor.docID,
        AnalyticsTimeFrames.THIS_MONTH,
        productsSoldCallback,
      ).then(u => snapshotListeners.push(u));
    }

    return () => {
      snapshotListeners.forEach(snapshot => snapshot());
    };
  }, [vendor]);

  return (
    <div className={`${CSSBlock}`}>
      <DashboardBadge
        title="Orders"
        subtitle="Month to date"
        value={orderCount.toString()}
      />
      <DashboardBadge
        title="Products Sold"
        subtitle="Month to date"
        value={productsSold.toString()}
      />
    </div>
  );
};

export default Badges;
