import { forwardRef, useEffect, useState } from 'react';

export interface Props {
  text: string;
  isActive?: boolean;
  onToggle: (active: boolean) => void;
  disabled?: boolean;
}

const DemmiChip = forwardRef<HTMLButtonElement, Props>(
  ({ text, disabled, isActive, onToggle }: Props, _) => {
    const CSSBlock = 'demmi-ui-chip';
    const [active, setActive] = useState(!!isActive);

    useEffect(() => {
      setActive(!!isActive);
    }, [isActive]);

    return (
      <label
        className={`${CSSBlock} ${active ? `${CSSBlock}--active` : ``} ${
          disabled ? `${CSSBlock}--disabled` : ``
        }`}>
        <input
          type="checkbox"
          disabled={disabled}
          checked={active}
          onChange={() => {
            onToggle(!active);
            setActive(!active);
          }}
        />
        <span className={`${CSSBlock}__text`}>{text}</span>
      </label>
    );
  },
);

DemmiChip.displayName = 'DemmiChip';
export default DemmiChip;
