import React from 'react';

import { faStyles } from '@demmi-ui/IconButton';

export interface Props {
  text: string;
  active?: boolean;
  faIcon?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

const SidebarButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ text, active, faIcon, disabled, onClick }: Props, ref) => {
    const CSSBlock = 'sidebar-button';

    return (
      <button
        className={`${CSSBlock} ${active ? `${CSSBlock}--active` : ``}`}
        disabled={disabled}
        onClick={onClick}
        ref={ref}>
        {faIcon && (
          <span className={`${CSSBlock}__icon`}>
            <i
              className={
                faStyles.find(item => faIcon.includes(item))
                  ? faIcon
                  : `fa-solid ${faIcon}`
              }></i>
          </span>
        )}
        <span className={`${CSSBlock}__text`}>{text}</span>
      </button>
    );
  },
);

SidebarButton.displayName = 'SidebarButton';
export default SidebarButton;
