import { Timestamp } from 'firebase/firestore';
import React from 'react';

import { updateOrderReviewResponse } from '@core/services/networkService/orders/orders';
import DemmiCard from '@demmi-ui/Card/Card';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

import ReviewCustomer from './ReviewCustomer';
import ReviewResponse from './ReviewResponse';
import { faStar } from '@fortawesome/pro-light-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';

export interface Props {
  vendor: DemmiFS.Vendor;
  review: DemmiFS.VendorReview;
  onUpdateReviewResponse: (
    reviewID: string,
    response: DemmiFS.FSVendorReviewResponse,
  ) => void;
}

const ReviewItem: React.FC<Props> = ({
  vendor,
  review,
  onUpdateReviewResponse,
}) => {
  const CSSBlock = 'review-item';

  const updateResponse = (response: string) => {
    const res: DemmiFS.FSVendorReviewResponse = {
      body: response.split('\n'),
      timestamp: Timestamp.now(),
    };
    demmiRequest(
      updateOrderReviewResponse(vendor.docID, review.docID, res),
    ).then(() => {
      onUpdateReviewResponse(review.docID, res);
    });
  };

  return (
    <div className={`${CSSBlock}__wrapper`}>
      <DemmiCard noPadding className={CSSBlock}>
        <div className={`${CSSBlock}__header`}>
          <div className={`${CSSBlock}__timestamp`}>
            Reviewed on:
            <span>
              {DemmiFS.getDateFromFirestore(review.timestamp)?.toDateString()}
            </span>
          </div>

          <div className={`${CSSBlock}__rating`}>
            {[...Array(5)].map((_, i) => (
              <FontAwesomeIcon
                icon={review!.rating < i ? faStar : faStarSolid}
                key={i}
              />
            ))}
          </div>
        </div>

        <div className={`${CSSBlock}__review`}>
          <div className={`${CSSBlock}__review-title`}>{review.title}</div>
          <div className={`${CSSBlock}__review-description`}>
            {review.body.map((paragraph, i) => (
              <div
                className={`${CSSBlock}__review-description-paragraph`}
                key={i}>
                {paragraph}
              </div>
            ))}
          </div>
        </div>

        <ReviewCustomer CSSBlock={CSSBlock} review={review} />
        <ReviewResponse
          CSSBlock={CSSBlock}
          review={review}
          onUpdateResponse={response => updateResponse(response)}
        />
      </DemmiCard>
    </div>
  );
};

export default ReviewItem;
