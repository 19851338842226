import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { updateVendorHours } from '@core/services/networkService/functions/_vendors';
import { selectVendor, setVendor } from '@core/store/appSlice/appSlice';
import DemmiButton from '@demmi-ui/Button';
import DemmiCard from '@demmi-ui/Card/Card';
import InputTime from '@demmi-ui/InputTime/InputTime';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS, DemmiHelpers } from '@subhanhabib/demmilib';

import DaysList from './DaysList';

const SettingsDeliveryDays: React.FC = () => {
  const CSSBlock = 'settings-delivery-days';
  const dispatch = useAppDispatch();
  const vendor = useAppSelector(selectVendor);
  const [hours, setHours] = useState<DemmiFS.VendorOpeningHours>(
    DemmiHelpers.Vendor.EmptyOpeningHours,
  );
  const [detectedChanges, setDetectedChanges] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const setValues = (vendor: DemmiFS.Vendor) => {
    setHours(vendor.shop?.openingHours);
  };

  useEffect(() => {
    if (vendor) setValues(vendor);
  }, [vendor]);

  useEffect(() => {
    if (vendor) {
      setDetectedChanges(
        DemmiHelpers.Vendor.openingHoursHasChanges(
          vendor.shop.openingHours,
          hours,
        ),
      );
      setIsValid(DemmiHelpers.Vendor.isOpeningHoursValid(hours));
    }
  }, [hours]);

  const discardChanges = () => {
    if (vendor) setValues(vendor);
  };

  const saveChanges = async () => {
    if (isValid && vendor) {
      const f: DemmiFS.Vendor = {
        ...vendor,
        shop: {
          ...vendor.shop,
          openingHours: hours,
        },
      };
      demmiRequest(updateVendorHours(f)).then(() => dispatch(setVendor(f)));
    }
  };

  return (
    <div className={`${CSSBlock}`}>
      <DaysList selectedDay={0} onClickDay={() => {}} />
      <div className={`${CSSBlock}__detail`}>
        <DemmiCard className={`${CSSBlock}__details-item`}>
          <div className={`${CSSBlock}__details-title`}>Monday</div>
          <div className={`${CSSBlock}__details-content`}>
            <div className={`${CSSBlock}__details-hours-item`}>
              <div className={`${CSSBlock}__details-hours-item-title`}>
                Start
              </div>
              <InputTime value={600} onChange={_ => {}} />
            </div>
          </div>
        </DemmiCard>

        <div className={`${CSSBlock}__buttons`}>
          <span style={{ alignSelf: 'center', display: 'flex', gap: '1rem' }}>
            <DemmiButton
              text={'Discard Changes'}
              faIcon="fa-ban"
              disabled={!detectedChanges}
              onClick={() => discardChanges()}
            />
            <DemmiButton
              text={'Save Changes'}
              faIcon="fa-regular fa-floppy-disk"
              disabled={!detectedChanges || !isValid}
              onClick={() => saveChanges()}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default SettingsDeliveryDays;
