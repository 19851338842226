import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Toolbar from '@components/toolbar/Toolbar';
import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import {
  getVendor,
  getVendorUser,
} from '@core/services/networkService/functions/_vendors';
import { getViewFromPath } from '@core/services/routingService';
import {
  selectIsLoading,
  selectVendor,
  setVendor,
} from '@core/store/appSlice/appSlice';
import {
  selectVendorUser,
  setVendorUser,
} from '@core/store/userSlice/userSlice';
import DemmiButton from '@demmi-ui/Button';
import { demmiRequest } from '@helpers/app.helper';
import { useAuth } from '@providers/authProvider';
import { DemmiHooks, Logger } from '@subhanhabib/demmilib';

import { auth } from '../firebase';
import ThemeSwitcher from './ThemeSwitcher';
import DemmiSupport from './demmiSupport/DemmiSupport';
import LoadingGlobal from './loadingIntro/LoadingGlobal';
import Notifications from './notifications/Notifications';
import ToastsArea from './toasts/ToastsArea';

interface Props extends PropsWithChildren {
  isMounted: boolean;
  shouldRender: boolean;
}

const VendorWrapper: React.FC<Props> = ({
  isMounted,
  shouldRender,
  children,
}) => {
  const CSSBlock = 'vendor-wrapper';
  const mountedStyle = { animation: 'inAnimation 400ms ease-in-out' };
  const unmountedStyle = { animation: 'outAnimation 200ms ease-in-out' };

  const user = auth.currentUser;
  const vendorUser = useAppSelector(selectVendorUser);
  const vendor = useAppSelector(selectVendor);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { logOut } = useAuth();

  useEffect(() => {
    if (user && !vendorUser) {
      Logger({ messages: ['useEffect'], objs: { user } }, VendorWrapper);
      demmiRequest(getVendorUser(user?.uid)).then(f =>
        dispatch(setVendorUser(f)),
      );
    }
  }, [user, vendorUser]);

  useEffect(() => {
    if (!vendor && vendorUser)
      demmiRequest(getVendor(vendorUser?.vendorID)).then(f =>
        dispatch(setVendor(f)),
      );
  }, [vendor, vendorUser]);

  // Global loading indicator
  const isLoading = useAppSelector(selectIsLoading);
  const [isGlobalLoadingMounted, setIsGlobalLoadingMounted] = useState(false);
  const shouldRenderGlobalLoading = DemmiHooks.useDelayUnmount(
    isGlobalLoadingMounted,
    400,
  );
  useEffect(() => {
    setIsGlobalLoadingMounted(isLoading);
  }, [isLoading]);

  return (
    <div className={CSSBlock}>
      <div className={`vendor-title`}>{vendor && vendor.name}</div>
      {vendor ? (
        <>
          <div
            className={`${CSSBlock}__content ${CSSBlock}--${getViewFromPath(
              pathname,
            )}`}
            style={isMounted ? mountedStyle : unmountedStyle}>
            {shouldRender && children}
          </div>

          <Toolbar />
        </>
      ) : (
        <div
          className={`${CSSBlock}__error`}
          style={isMounted ? mountedStyle : unmountedStyle}>
          <div className={`${CSSBlock}__error-title`}>Something went wrong</div>
          <div className={`${CSSBlock}__error-subtitle`}>
            Most likely invalid authentication.
          </div>
          <DemmiButton
            text="Logout"
            onClick={() => logOut(() => dispatch({ type: 'LOGOUT' }))}
          />
        </div>
      )}

      <ThemeSwitcher />
      <Notifications />
      <DemmiSupport />
      <ToastsArea />

      <div id="toasts-area" />
      <div id="modal-container" />
      <div id="notifications-panel" />
      <div id="demmi-support-panel" />

      {shouldRenderGlobalLoading && (
        <LoadingGlobal isMounted={isGlobalLoadingMounted} />
      )}
    </div>
  );
};

export default VendorWrapper;
