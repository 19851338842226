import {
  Auth,
  AuthError,
  User,
  UserCredential,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { RoutingViews, routingPaths } from '@core/services/routingService';
import { DemmiFS, Logger } from '@subhanhabib/demmilib';

import { auth } from '../firebase';

export interface AuthProviderProps {
  userData: User | null;
  children?: ReactNode;
}

export interface UserContextState {
  isAuthenticated: boolean;
  isLoading: boolean;
  id?: string;
}

export const UserStateContext = createContext<UserContextState>(
  {} as UserContextState,
);

export const useUserContext = (): UserContextState => {
  return useContext(UserStateContext);
};

export interface AuthContextModel {
  auth: Auth;
  user: User | null;
  signIn: (email: string, password: string) => Promise<UserCredential | void>;
  createUser: (email: string, password: string) => void;
  logOut: (onLogout?: () => void) => void;
}

export const AuthContext = createContext<AuthContextModel>(
  {} as AuthContextModel,
);

export function useAuth(): AuthContextModel {
  return useContext(AuthContext);
}

export const AuthProvider = ({
  userData,
  children,
}: AuthProviderProps): JSX.Element => {
  const [user, setUser] = useState<User | null>(userData);
  const navigate = useNavigate();

  const signIn = (
    email: string,
    password: string,
  ): Promise<UserCredential | void> => {
    return signInWithEmailAndPassword(auth, email, password)
      .then(_ => {
        // const user = userCredential.user;
        navigate(routingPaths[RoutingViews.HOME], { replace: true });
        // ...
      })
      .catch((error: AuthError) => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
        throw DemmiFS.prettifyAuthError(error);
        // Handle eror codes:
        // https://firebase.google.com/docs/reference/js/auth#autherrorcodes
      });
  };

  const createUser = (email: string, password: string) => {
    return createUserWithEmailAndPassword(auth, email, password)
      .then(_ => {
        // const user = userCredential.user;
        // ...
      })
      .catch(_ => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // Handle eror codes:
        // https://firebase.google.com/docs/reference/js/auth#autherrorcodes
      });
  };

  const logOut = (onLogout?: () => void) => {
    signOut(auth).then(() => {
      if (onLogout) onLogout();
      navigate('/', { replace: true });
    });
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      Logger(
        { messages: ['auth.onAuthStateChanged'], objs: { user } },
        AuthProvider,
      );
      setUser(user);
    });
    return unsubscribe;
  }, []);

  const values = useMemo(
    () => ({
      auth,
      user,
      signIn,
      createUser,
      logOut,
    }),
    [user],
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
