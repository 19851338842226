import React, { useRef, useState } from 'react';

import {
  checkCodeAvailability,
  createCoupon,
} from '@core/services/networkService/functions/_coupons';
import DemmiButton from '@demmi-ui/Button';
import DemmiIconButton, { IconButtonSize } from '@demmi-ui/IconButton';
import DemmiCardButtonArea from '@demmi-ui/Modal/ModalButtonArea';
import DemmiModalCore, { DemmiModalCoreRef } from '@demmi-ui/Modal/ModalCore';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

import ModalCreateCouponContent from './ModalCreateCouponContent';

interface Props {
  onClose: () => void;
  onConfirm: (code: string) => void;
}

const ModalCreateCoupon: React.FC<Props> = ({ onClose, onConfirm }) => {
  const CSSBlock = 'modal-create-coupon';
  const modalRef = useRef<DemmiModalCoreRef>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [coupon, setCoupon] = useState<DemmiFS.Coupon>();
  const [isDoingRequest, setIsDoingRequest] = useState(false);

  /**
   * 0 = Doing nothing
   * 1 = Creating product
   * -1 = Code already exists
   * -2 = Failed to create
   */
  const [state, setState] = useState(0);
  const [isValid, setIsValid] = useState(false);

  const gracefulExit = (postExit: () => void) => {
    modalRef.current ? modalRef.current.gracefulClose(postExit) : postExit();
    setTimeout(() => {
      setIsOpen(false);
      setState(0);
    }, 500);
  };

  const pushCoupon = async () => {
    if (isValid && coupon) {
      setIsDoingRequest(true);
      await demmiRequest(checkCodeAvailability(coupon.docID))
        .then(isAvailable => {
          if (isAvailable) {
            demmiRequest(createCoupon(coupon)).then(_ => {
              gracefulExit(() => onConfirm(coupon.docID));
            });
          } else setState(-1);
        })
        .catch(() => setState(-2));
      setIsDoingRequest(false);
    }
  };

  const buttons = [
    <DemmiButton
      key={0}
      text="Discard"
      outlined
      onClick={() => gracefulExit(onClose)}
    />,
    <DemmiButton
      key={1}
      text="Create Coupon"
      disabled={!isValid}
      onClick={() => pushCoupon()}
    />,
  ];

  return (
    <>
      <DemmiIconButton
        faIcon="fa-plus"
        onClick={() => setIsOpen(true)}
        size={IconButtonSize.SMALL}
      />
      <DemmiModalCore
        ref={modalRef}
        isOpen={isOpen}
        onExited={() => {
          setIsOpen(false);
          onClose();
        }}
        contentClassName={CSSBlock}
        allowBackdropClose={false}>
        <ModalCreateCouponContent
          state={state}
          CSSBlock={CSSBlock}
          setCoupon={setCoupon}
          setIsValid={setIsValid}
          isDoingRequest={isDoingRequest}
        />
        <DemmiCardButtonArea
          buttons={buttons}
          isDoingRequest={isDoingRequest}
        />
      </DemmiModalCore>
    </>
  );
};

export default ModalCreateCoupon;
