import { Timestamp } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';

import FakePhone from '@components/fakePhone/FakePhone';
import ModalDelete from '@components/modals/ModalDelete';
import ModalPublish from '@components/modals/ModalPublish';
import {
  getProduct,
  updateProduct,
  updateProductArchived,
  updateProductPublished,
} from '@core/services/networkService/functions/_product';
import DemmiButton from '@demmi-ui/Button';
import { demmiRequest } from '@helpers/app.helper';
import {
  isProductDetailsValid,
  productHasChanges,
} from '@helpers/products.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

import ProductCategory from './partials/Category';
import Description from './partials/Description';
import Images from './partials/Images';
import Tags from './partials/Tags';

interface Props {
  product: DemmiFS.Product;
  demmiConstants: DemmiFS.FSDemmiConstants;
  onChangeArchived: (id: string) => void;
}

const ProductDetails: React.FC<Props> = ({
  product,
  demmiConstants,
  onChangeArchived: onArchived,
}) => {
  const CSSBlock = 'product-details';

  const [coreProduct, setCoreProduct] = useState<DemmiFS.Product>();
  const [isPublished, setIsPublished] = useState<boolean>();
  const [isArchived, setIsArchived] = useState<boolean>();
  const [name, setName] = useState('');
  const [categoryID, setCategoryID] = useState<string | undefined>();
  const [shortDescription, setShortDescription] = useState('');
  const [description, setDescription] = useState('');
  const [images, setImages] = useState<DemmiFS.DemmiImageResource[]>([]);
  const [tags, setTags] = useState<string[]>([]);

  const [detectedChanges, setDetectedChanges] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const setValues = (product: DemmiFS.Product) => {
    setIsPublished(!!product.isPublished);
    setIsArchived(!!product.isArchived);
    setName(product.name);
    setCategoryID(product.categoryID ?? '');
    setShortDescription(product.shortDescription);
    setDescription(product.longDescription.join('\n'));
    setImages(product.images);
    setTags(product.tags);
  };

  useEffect(() => {
    demmiRequest(getProduct(product.docID)).then(p => {
      setCoreProduct(p);
      if (p) setValues(p);
    });
  }, [product]);

  useEffect(() => {
    setDetectedChanges(
      !coreProduct ||
        productHasChanges(coreProduct, {
          name,
          categoryID: categoryID ?? '',
          shortDescription,
          longDescription: description.split('\n'),
          images,
          tags,
        }),
    );
    setIsValid(
      isProductDetailsValid({
        name,
        categoryID: categoryID ?? '',
        shortDescription,
        longDescription: description.split('\n'),
        images,
        tags,
      }),
    );
  }, [
    name,
    categoryID,
    shortDescription,
    description,
    images,
    tags,
    coreProduct,
  ]);

  const discardChanges = () => {
    if (coreProduct) setValues(coreProduct);
  };

  const saveChanges = async () => {
    if (
      coreProduct &&
      name.length > 0 &&
      description.length > 0 &&
      categoryID
    ) {
      const product: DemmiFS.Product = {
        docID: coreProduct.docID,
        vendorID: coreProduct.vendorID,
        name,
        categoryID,
        shortDescription,
        longDescription: description
          .split('\n')
          .filter(value => value.length > 0),
        images,
        tags,
        variants: [],
        isPublished: false,
        isArchived: false,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      };

      demmiRequest(updateProduct(product)).then(() => setCoreProduct(product));
    }
  };

  const onChangePublished = (isPublished: boolean) => {
    if (product)
      return demmiRequest(updateProductPublished(product.docID, isPublished))
        .then(() => {
          setIsPublished(isPublished);
          return true;
        })
        .catch(() => false);
  };

  const onChangeArchived = async (isArchived: boolean) => {
    if (product) {
      await demmiRequest(updateProductArchived(product.docID, isArchived))
        .then(() => {
          setIsArchived(isArchived);
          return true;
        })
        .catch(() => false);
      onArchived(product.docID);
    }
  };

  return (
    <>
      <div className="inventory__live-preview">
        <FakePhone
          app={1}
          product={{
            docID: '',
            vendorID: '',
            name,
            categoryID: categoryID ?? '',
            shortDescription,
            longDescription: description
              .split('\n')
              .filter(value => value.length > 0),
            images,
            tags,
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
            variants: [],
            isPublished: true,
            isArchived: false,
          }}
        />
      </div>
      <div className={CSSBlock}>
        <div
          className={`${CSSBlock}__status ${
            isArchived
              ? `${CSSBlock}__status--archived`
              : isPublished
                ? `${CSSBlock}__status--published`
                : ''
          }`}>
          <div className={`${CSSBlock}__status-text`}>
            {isArchived
              ? 'Archived'
              : isPublished
                ? 'Published'
                : 'Not Published'}
          </div>
        </div>
        <div className={`${CSSBlock}__details`}>
          <Description
            CSSBlock={CSSBlock}
            name={name}
            setName={setName}
            shortDescription={shortDescription}
            setShortDescription={setShortDescription}
            description={description}
            setDescription={setDescription}
          />
          <ProductCategory
            categoryID={categoryID}
            setCategoryID={setCategoryID}
          />
          <Images images={images} setImages={setImages} product={product} />
          <Tags
            productTags={demmiConstants.product_tags ?? []}
            tags={tags}
            setTags={setTags}
          />
        </div>
        <div className={`${CSSBlock}__buttons-row`}>
          <ModalDelete
            isArchived={!!isArchived}
            onClose={() => {}}
            onConfirm={() => onChangeArchived(!isArchived)}
            name={product.name}
          />
          <DemmiButton
            text={'Discard Changes'}
            faIcon="fa-ban"
            disabled={!detectedChanges}
            onClick={() => discardChanges()}
          />
          <DemmiButton
            text={'Save Changes'}
            faIcon="fa-regular fa-floppy-disk"
            disabled={!detectedChanges || !isValid}
            onClick={() => saveChanges()}
          />
          <ModalPublish
            isPublished={!!isPublished}
            onClose={() => {}}
            onConfirm={() => onChangePublished(!isPublished)}
          />
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
