import { QueryDocumentSnapshot } from 'firebase/firestore';

import { DemmiFS } from '@subhanhabib/demmilib';

export const parseToOrder = (
  vendorID: string,
  doc: QueryDocumentSnapshot<DemmiFS.FSOrder>
): DemmiFS.Order => {
  const vendors = doc.data()['vendors'];
  const status = DemmiFS.OrderStatusHelper.getOrderStatus(doc.data().status);
  const coupon: DemmiFS.FSOrderVendorCoupon | undefined =
    vendors[vendorID].coupon && vendors[vendorID].coupon?.type
      ? {
          ...vendors[vendorID].coupon!,
          type: DemmiFS.CouponHelper.getType(vendors[vendorID].coupon!.type),
        }
      : undefined;
  const products = vendors[vendorID].products.map(p => ({
    ...p,
    variantUnit:
      DemmiFS.UnitsHelper.getWeightUnit(p.variantUnit) ?? p.variantUnit,
  }));

  return {
    docID: doc.id,
    ...(doc.data() as DemmiFS.FSOrder),
    status: status,
    vendor: { ...vendors[vendorID], products, coupon },
    timeline: doc.data().timeline
      ? doc.data().timeline.map(update => {
          return {
            ...update,
            statusUpdate: DemmiFS.OrderStatusHelper.getOrderVendorStatus(
              update.statusUpdate
            ),
            updatedBy: DemmiFS.OrderTimelineHelper.getUpdatedBy(
              update.updatedBy
            ),
          };
        })
      : [],
  };
};
