import React from 'react';

import ComingSoon from '@components/comingSoon/ComingSoon';

const Analytics: React.FC = () => {
  const CSSBlock = 'analytics';
  // const vendor = useAppSelector(selectVendor);

  return (
    <div className={`${CSSBlock}`}>
      <ComingSoon />
    </div>
  );
};

export default Analytics;
