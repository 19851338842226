import React, { HTMLInputTypeAttribute, forwardRef } from 'react';

export interface DemmiInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  placeholder?: string;
  type: HTMLInputTypeAttribute;
  value?: string;
  disabled?: boolean;
  invert?: boolean;
  // error?: boolean;
  onChangeValue: (val: string) => void;
}

const DemmiInput = forwardRef<HTMLInputElement, DemmiInputProps>(
  (
    {
      placeholder,
      type,
      value,
      disabled,
      invert,
      // error,
      onChangeValue,
      ...props
    }: DemmiInputProps,
    ref,
  ) => {
    const CSSBlock = 'demmi-ui-input';

    return (
      <input
        {...props}
        className={`${CSSBlock} ${invert ? `${CSSBlock}--invert` : ''}`}
        type={type}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        min={0}
        ref={ref}
        onChange={e => onChangeValue(e.target.value)}
      />
    );
  },
);

DemmiInput.displayName = 'DemmiInput';
export default DemmiInput;
