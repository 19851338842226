import { Timestamp } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';

import AsyncImage from '@components/asyncImage/AsyncImage';
import { useAppSelector } from '@core/hooks/appHooks';
import { selectDemmiConstants } from '@core/store/appSlice/appSlice';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DemmiFS } from '@subhanhabib/demmilib';
import {
  faBagShopping,
  faChevronLeft,
  faLocationDot,
} from '@fortawesome/pro-solid-svg-icons';

const fakeProduct: DemmiFS.Product = {
  docID: '',
  vendorID: '',
  name: 'Product Name',
  categoryID: '',
  shortDescription: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris at dapibus nulla. Integer cursus justo quam, ac sagittis orci fringilla vitae.`,
  longDescription: [
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris at dapibus nulla. Integer cursus justo quam, ac sagittis orci fringilla vitae. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris at dapibus nulla. Integer cursus justo quam, ac sagittis orci fringilla vitae.',
    'Mauris at dapibus nulla. Integer cursus justo quam, ac sagittis orci fringilla vitae. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris at dapibus nulla. Integer cursus justo quam, ac sagittis orci fringilla vitae.',
  ],
  images: [],
  tags: [],
  variants: [],
  isPublished: false,
  isArchived: false,
  createdAt: Timestamp.now(),
  updatedAt: Timestamp.now(),
};

interface Props {
  productImages: string[];
  product?: DemmiFS.Product;
  vendor: DemmiFS.Vendor;
  vendorAddress: string;
}

const DisplayProduct: React.FC<Props> = ({
  productImages,
  product,
  vendor,
  vendorAddress,
}) => {
  const CSSBlock = 'display-product';
  const demmiConstants = useAppSelector(selectDemmiConstants);
  const [visibleProduct, setVisibleProduct] = useState(product ?? fakeProduct);
  const [categoryIcon, setCategoryIcon] = useState<IconDefinition>(
    DemmiFS.CategoriesHelper.getIconFromCategoryID(
      demmiConstants?.categories,
      product?.categoryID,
    ),
  );

  useEffect(() => {
    setVisibleProduct(product ?? fakeProduct);
  }, [product]);

  useEffect(() => {
    setCategoryIcon(
      DemmiFS.CategoriesHelper.getIconFromCategoryID(
        demmiConstants?.categories,
        product?.categoryID,
      ),
    );
  }, [product, demmiConstants]);

  const getCategory = () => {
    if (demmiConstants?.categories) {
      let cat: DemmiFS.Category | undefined;
      demmiConstants.categories.forEach(c =>
        c.children?.forEach((x: DemmiFS.Category) => {
          if (x.id === product?.categoryID) cat = x;
        }),
      );
      if (cat) return cat.name;
    }
    return 'Product Category';
  };

  return (
    <div className={`${CSSBlock}__app`}>
      <div className={`${CSSBlock}__header`}>
        <div className={`${CSSBlock}__title`}>
          <span className={`${CSSBlock}__icon`}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </span>
          {visibleProduct.name}
          <span className={`${CSSBlock}__icon`}>
            <FontAwesomeIcon icon={faBagShopping} />
          </span>
        </div>
      </div>
      <div className={`${CSSBlock}__content`}>
        <div className={`${CSSBlock}__gallery`}>
          <div className={`${CSSBlock}__gallery-images`}>
            <div className={`${CSSBlock}__gallery-image`}>
              {productImages.length > 0 && (
                <AsyncImage
                  src={
                    visibleProduct.images.length > 0
                      ? visibleProduct.images[0].main
                      : productImages[
                          Math.floor(Math.random() * productImages.length)
                        ]
                  }
                />
              )}
            </div>
          </div>
          <div className={`${CSSBlock}__gallery-toolbar`}>
            <div
              className={`${CSSBlock}__gallery-toolbar-indicator`}
              style={{
                width: `${
                  visibleProduct.images.length > 0
                    ? (1 / visibleProduct.images.length) * 100
                    : 24
                }%`,
              }}></div>
          </div>
        </div>
        <div className={`${CSSBlock}__card ${CSSBlock}__card-location`}>
          {vendorAddress}
          <span className={`${CSSBlock}__icon`}>
            <FontAwesomeIcon icon={faLocationDot} />
          </span>
        </div>
        <div className={`${CSSBlock}__card`}>
          <div className={`${CSSBlock}__category`}>
            <span className={`${CSSBlock}__icon`}>
              <FontAwesomeIcon icon={categoryIcon} />
            </span>
            {getCategory()}
          </div>
          <div className={`${CSSBlock}__card-description`}>
            {visibleProduct.shortDescription}
          </div>
        </div>
        <div className={`${CSSBlock}__card`}>
          <div className={`${CSSBlock}__card-title`}>About This Product</div>
          <div className={`${CSSBlock}__card-description`}>
            {visibleProduct.longDescription.map((desc, i) => (
              <span key={i}>{desc}</span>
            ))}
          </div>
        </div>
        <div className={`${CSSBlock}__button-wrapper`}>
          <div className={`${CSSBlock}__button`}>
            <div className={`${CSSBlock}__button-left`}>
              <span>
                {DemmiFS.CurrencyHelper.getCurrencySymbolFromCountryCode(
                  vendor?.address?.countryCode ?? '',
                )}
              </span>
              1.19
              <span>per unit</span>
            </div>
            <div className={`${CSSBlock}__button-right`}>Add to basket</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisplayProduct;
