import {
  DocumentData,
  QueryDocumentSnapshot,
  Unsubscribe,
  getDocs,
  onSnapshot,
} from 'firebase/firestore';

import { DemmiFS } from '@subhanhabib/demmilib';

import { FSCustomer } from '../customer/_customer';
import { getReviewCustomerFromRequests, parseToVendorReview } from './_helper';
import { vendorReviewsQuery, vendorReviewsQueryLimited } from './_queries';

export const listenToReviews = async (
  vendorID: string,
  callback: (reviews: DemmiFS.VendorReview[]) => void,
  limit?: number,
): Promise<Unsubscribe> => {
  const ref =
    limit === undefined
      ? vendorReviewsQuery(vendorID)
      : vendorReviewsQueryLimited(vendorID, limit);

  return onSnapshot(ref, async querySnapshot => {
    const reviews: DemmiFS.VendorReview[] = [];
    const customerRequests: {
      [key: string]: Promise<DemmiFS.FSUser | undefined>;
    } = {};

    querySnapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
      if (
        !customerRequests[doc.data()['customerID']] &&
        doc.data()['customerID']
      ) {
        customerRequests[doc.data()['customerID']] = FSCustomer.doc.getByDocID(
          doc.data()['customerID'],
        );
      }
    });

    await Promise.all(Object.values(customerRequests)).then(customers => {
      querySnapshot.forEach(
        async (doc: QueryDocumentSnapshot<DemmiFS.FSVendorReview>) => {
          reviews.push(
            parseToVendorReview(
              vendorID,
              doc,
              getReviewCustomerFromRequests(
                customerRequests,
                customers,
                doc.data()['customerID'],
              ),
            ),
          );
        },
      );
    });

    callback(reviews);
  });
};

export const getAllReviews = async (
  vendorID: string,
): Promise<DemmiFS.VendorReview[]> => {
  const querySnapshot = await getDocs(
    vendorReviewsQuery(vendorID),
    // FSCollections.OrderReviews([vendorID, FSSubCollectionNames.REVIEWS]) // only if this vendor exists
  );

  const reviews: DemmiFS.VendorReview[] = [];
  const customerRequests: {
    [key: string]: Promise<DemmiFS.FSUser | undefined>;
  } = {};

  querySnapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
    if (
      !customerRequests[doc.data()['customerID']] &&
      doc.data()['customerID']
    ) {
      customerRequests[doc.data()['customerID']] = FSCustomer.doc.getByDocID(
        doc.data()['customerID'],
      );
    }
  });

  await Promise.all(Object.values(customerRequests)).then(customers => {
    querySnapshot.forEach(
      async (doc: QueryDocumentSnapshot<DemmiFS.FSVendorReview>) => {
        reviews.push(
          parseToVendorReview(
            vendorID,
            doc,
            getReviewCustomerFromRequests(
              customerRequests,
              customers,
              doc.data()['customerID'],
            ),
          ),
        );
      },
    );
  });
  return reviews;
};
