import React, {
  PropsWithChildren,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import ReactPortal from '@demmi-ui/ReactPortal';

export type DemmiMenuCoreRef = {
  gracefulClose: (postExit: () => void) => void;
};

interface MenuCoreProps extends PropsWithChildren {
  isOpen: boolean;
  onExited: () => void;
  triggerBounds: DOMRect;
}

const MenuCore = React.forwardRef<DemmiMenuCoreRef, MenuCoreProps>(
  ({ isOpen, onExited, children, triggerBounds }: MenuCoreProps, ref) => {
    if (!isOpen) return null;

    const CSSBlock = 'demmi-ui-menu';
    const menuRef = useRef<HTMLDivElement | null>(null);
    const [menuPosition, setMenuPosition] = useState([0, 0]);
    const [menuClass, setMenuClass] = useState('');

    const view = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    useImperativeHandle(ref, () => ({
      gracefulClose(postExit: () => void) {
        setMenuClass('');
        setTimeout(() => postExit(), 300);
      },
    }));

    const onClose = () => {
      setMenuClass('');
      setTimeout(() => onExited(), 300);
    };

    useEffect(() => {
      const onKeyDown = (e: globalThis.KeyboardEvent) => {
        if (e.key === 'Escape') onClose();
      };
      document.addEventListener('keydown', onKeyDown);
      return () => document.removeEventListener('keydown', onKeyDown);
    }, []);

    const calculatorMenuPosition = (menuEl: HTMLDivElement) => {
      const menuBounds = menuEl.getBoundingClientRect();
      const gap = 8;
      let offsetX = triggerBounds.x;
      let offsetY = triggerBounds.y + triggerBounds.height + gap;
      if (view.width - (offsetX + menuBounds.width) < 0) {
        offsetX = offsetX - menuBounds.width + triggerBounds.width;
      }
      if (view.height - (offsetY + menuBounds.height) < 0) {
        offsetY = offsetY - menuBounds.height - triggerBounds.height - gap * 2;
      }
      setMenuPosition([offsetX, offsetY]);
      setMenuClass(`${CSSBlock}--visible`);
    };

    useEffect(() => {
      requestAnimationFrame(() => {
        if (menuRef.current) calculatorMenuPosition(menuRef.current);
      });
    }, [menuRef]);

    return (
      <ReactPortal wrapperId="demmi-ui-menu-wrapper">
        <div
          className={`${CSSBlock} ${menuClass}`}
          ref={menuRef}
          style={{
            left: `${menuPosition[0]}px`,
            top: `${menuPosition[1]}px`,
          }}>
          <div className={`${CSSBlock}__card`}>{children}</div>
        </div>
      </ReactPortal>
    );
  },
);

MenuCore.displayName = 'MenuCore';
export default MenuCore;
