import { Unsubscribe } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';

import ElementLoadingIndicator from '@components/loadingIntro/ElementLoadingIndicator';
import { useAppSelector } from '@core/hooks/appHooks';
import { listenToRefundRequest } from '@core/services/networkService/refundRequests/refundRequests';
import { selectVendor } from '@core/store/appSlice/appSlice';
import DemmiButton from '@demmi-ui/Button';
import DemmiTimeline, { DemmiTimelineItem } from '@demmi-ui/Timeline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DemmiFS } from '@subhanhabib/demmilib';

import OrderItemTabRefundDetails from './_tab-refund-details';
import OrderItemTabRefundUpdate from './_tab-refund-update';
import { faMoneyBillTransfer } from '@fortawesome/pro-light-svg-icons';

interface Props {
  refundRequestID?: string;
  order: DemmiFS.Order;
}

const OrderItemTabRefund: React.FC<Props> = ({ refundRequestID, order }) => {
  const CSSBlock = 'order-refund-request';
  const vendor = useAppSelector(selectVendor);
  const [isLoading, setIsLoading] = useState(false);
  const [refundRequest, setRefundRequest] = useState<DemmiFS.RefundRequest>();

  useEffect(() => {
    let snapshotListener: Unsubscribe | undefined;
    if (vendor && refundRequestID) {
      setIsLoading(true);
      const cb = (r?: DemmiFS.RefundRequest) => {
        setRefundRequest(r);
        setIsLoading(false);
      };
      listenToRefundRequest(refundRequestID, cb).then(
        u => (snapshotListener = u),
      );
    }

    return () => {
      if (snapshotListener) snapshotListener();
    };
  }, [vendor, refundRequestID]);

  const timelineItems: DemmiTimelineItem[] = refundRequest
    ? refundRequest.updates.map(update => ({
        ...(update.statusUpdate
          ? {
              title: `Refund ${DemmiFS.RefundHelper.getRefundStatusText(
                update.statusUpdate,
              ).toLowerCase()}`,
            }
          : {}),
        date: DemmiFS.getDateFromFirestore(update.timestamp),
        ...(update.text
          ? {
              text: update.text,
              textBadge: update.vendorID ? 'You' : 'Customer',
            }
          : {}),
      }))
    : [];

  return refundRequest ? (
    <div
      className={`${CSSBlock} ${isLoading ? `${CSSBlock}--is-loading` : ''}`}>
      <div className={`${CSSBlock}__title`}>
        <FontAwesomeIcon size={'lg'} icon={faMoneyBillTransfer} />
        {DemmiFS.RefundHelper.getRefundTypeText(refundRequest.type)} refund of{' '}
        <span>
          {DemmiFS.CurrencyHelper.getCurrencySymbolFromCurrencyCode(
            refundRequest.costEstimate.currencyCode,
          )}
          {Number(refundRequest.costEstimate.totalRefund).toFixed(2)}
        </span>{' '}
        {DemmiFS.RefundHelper.getCurrentRefundStatus(refundRequest)}
      </div>
      <OrderItemTabRefundDetails
        CSSBlock={CSSBlock}
        refundRequest={refundRequest}
        order={order}
      />
      <DemmiTimeline items={timelineItems} />
      {DemmiFS.RefundHelper.getCurrentRefundStatus(refundRequest) ===
      DemmiFS.RefundHelper.RefundStatus.REQUESTED ? (
        <div className={`${CSSBlock}__action-area`}>
          <div className={`${CSSBlock}__key-val`}>
            <div className={`${CSSBlock}__buttons`}>
              <DemmiButton text="Accept" onClick={() => {}} />
              <DemmiButton text="Reject" onClick={() => {}} />
            </div>
          </div>
        </div>
      ) : (
        <> </>
      )}

      <ElementLoadingIndicator isLoading={isLoading} />

      {vendor && refundRequest && (
        <OrderItemTabRefundUpdate
          CSSBlock={CSSBlock}
          vendorID={vendor.docID}
          refundRequest={refundRequest}
        />
      )}
    </div>
  ) : (
    <div className={`${CSSBlock}__none`}>This order has no refund request.</div>
  );
};

export default OrderItemTabRefund;
