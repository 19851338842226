import React, { useState } from 'react';

import DemmiButton from '@demmi-ui/Button';
import DemmiIconButton from '@demmi-ui/IconButton';
import DemmiTextArea from '@demmi-ui/TextArea';
import { DemmiFS } from '@subhanhabib/demmilib';
import { faCancel, faEdit, faSave } from '@fortawesome/pro-regular-svg-icons';

export interface Props {
  review: DemmiFS.VendorReview;
  CSSBlock: string;
  onUpdateResponse: (val: string) => void;
}

const ReviewResponse: React.FC<Props> = ({
  review,
  CSSBlock,
  onUpdateResponse,
}) => {
  const [response, setResponse] = useState<string>(
    review.response ? review.response.body.join('\n') : '',
  );
  const [editMode, setEditMode] = useState(false);

  return (
    <div className={`${CSSBlock}__review-response`}>
      {editMode ? (
        <>
          <div className={`${CSSBlock}__review-response-title`}>
            Your response
            <div className={`${CSSBlock}__review-response-title-buttons`}>
              <DemmiIconButton
                variant="delete"
                icon={faCancel}
                onClick={() => {
                  setResponse(review.response?.body.join('\n') ?? '');
                  setEditMode(false);
                }}
              />
              <DemmiIconButton
                variant="save"
                icon={faSave}
                onClick={() => {
                  onUpdateResponse(response);
                  setEditMode(false);
                }}
              />
            </div>
          </div>
          <DemmiTextArea
            placeholder="Your response"
            value={response}
            maxLength={1000}
            maxHeight={24}
            onChange={val => setResponse(val)}
          />
        </>
      ) : review.response ? (
        <>
          <div className={`${CSSBlock}__review-response-title`}>
            Your response
            <DemmiIconButton
              icon={faEdit}
              onClick={() => setEditMode(!editMode)}
            />
          </div>
          {review.response.body.map((paragraph, i) => (
            <div
              className={`${CSSBlock}__review-description-paragraph`}
              key={i}>
              {paragraph}
            </div>
          ))}
        </>
      ) : (
        <div className={`${CSSBlock}__review-no-response`}>
          You&apos;ve not yet responded to this review.
          <DemmiButton
            text="Respond now"
            faIcon="fa-edit"
            onClick={() => setEditMode(true)}
          />
        </div>
      )}
    </div>
  );
};

export default ReviewResponse;
