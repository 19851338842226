import React from 'react';

import { faStyles } from './IconButton';

export enum DemmiButtonSize {
  S = 'small',
  M = 'medium',
  L = 'large',
}

export interface DemmiButtonProps {
  text: string;
  faIcon?: string;
  disabled?: boolean;
  outlined?: boolean;
  variant?: 'delete' | 'save';
  onClick?: (e: React.MouseEvent) => void;
  styles?: React.CSSProperties;
  size?: DemmiButtonSize;
}

const DemmiButton = React.forwardRef<HTMLButtonElement, DemmiButtonProps>(
  (
    {
      text,
      faIcon,
      disabled,
      outlined,
      variant,
      onClick,
      styles,
      size,
    }: DemmiButtonProps,
    ref,
  ) => {
    const CSSBlock = 'demmi-ui-button';

    return (
      <button
        className={`${CSSBlock} ${outlined ? `${CSSBlock}--outlined` : ``} ${
          variant ? `${CSSBlock}--${variant}` : ``
        } ${size ? `${CSSBlock}--size-${size}` : ''}`}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
        style={styles}>
        {faIcon && (
          <span className={`${CSSBlock}__icon`}>
            <i
              className={
                faStyles.find(item => faIcon.includes(item))
                  ? faIcon
                  : `fa-solid ${faIcon}`
              }></i>
          </span>
        )}
        <span className={`${CSSBlock}__text`}>{text}</span>
      </button>
    );
  },
);

DemmiButton.displayName = 'DemmiButton';
export default DemmiButton;
