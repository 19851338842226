import { DemmiFS } from '@subhanhabib/demmilib';

export const notificationToToast = (
  notification: DemmiFS.NotificationVendor
): { title: string; body: string } | undefined => {
  switch (notification.type) {
    case DemmiFS.NotificationHelper.Vendor.NotificationType
      .VENDOR_REFUND_REQUEST:
      return { title: `New Refund Request`, body: notification.message };
    case DemmiFS.NotificationHelper.Vendor.NotificationType
      .VENDOR_REFUND_UPDATE:
      return {
        title: `Update available for refund request`,
        body: notification.message,
      };
    case DemmiFS.NotificationHelper.Vendor.NotificationType.VENDOR_ORDER_NEW:
      return { title: `Incoming Order!`, body: notification.message };
    case DemmiFS.NotificationHelper.Vendor.NotificationType.VENDOR_ORDER_UPDATE:
      return { title: `Order Update!`, body: notification.message };
    default:
      return;
  }
};
