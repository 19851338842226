import React from 'react';

import ModalCreateCoupon from '@components/modals/CreateCoupon/ModalCreateCoupon';
import DemmiCard from '@demmi-ui/Card/Card';
import DemmiIconButton, { IconButtonSize } from '@demmi-ui/IconButton';
import { DemmiFS } from '@subhanhabib/demmilib';

import CouponSidebarButton from './CouponSidebarButton';

interface Props {
  selectedCoupon?: DemmiFS.Coupon;
  onClick: (coupon: DemmiFS.Coupon) => void;
  onClickRefresh: () => void;
  onCreated: (code: string) => void;
  coupons: DemmiFS.Coupon[];
}

const CouponsSidebar: React.FC<Props> = ({
  selectedCoupon,
  onCreated,
  onClickRefresh,
  coupons,
  onClick,
}) => {
  const CSSBlock = 'sidebar';

  return (
    <div className={`${CSSBlock} ${CSSBlock}--coupons`}>
      <DemmiCard darkOnly className={`${CSSBlock}__header`}>
        <div className={`${CSSBlock}__title`}>
          Coupons
          <div className={`${CSSBlock}__title-buttons`}>
            <DemmiIconButton
              faIcon={'fa-rotate-right'}
              onClick={onClickRefresh}
              size={IconButtonSize.SMALL}
            />
            <ModalCreateCoupon onClose={() => {}} onConfirm={onCreated} />
          </div>
        </div>
      </DemmiCard>
      <div className={`${CSSBlock}__content`}>
        {coupons.map((coupon, i) => (
          <CouponSidebarButton
            key={i}
            coupon={coupon}
            onClick={() => onClick(coupon)}
            active={selectedCoupon?.docID === coupon.docID}
          />
        ))}
      </div>
    </div>
  );
};

export default CouponsSidebar;
