import { QueryDocumentSnapshot } from 'firebase/firestore';

import { DemmiFS } from '@subhanhabib/demmilib';

export const parseToNotification = (
  doc: QueryDocumentSnapshot<DemmiFS.FSNotificationVendor>
): DemmiFS.NotificationVendor => {
  const type = DemmiFS.NotificationHelper.Vendor.getNotificationType(
    doc.data().type
  );

  return {
    docID: doc.id,
    ...(doc.data() as DemmiFS.FSNotificationVendor),
    type,
  };
};
