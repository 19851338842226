import { DemmiLogType, Logger } from '@subhanhabib/demmilib';
import { HTMLProps, PropsWithChildren, useState } from 'react';

/**
 * Interfaces
 */
export interface DemmiCardPropsDefault extends Base {
  titleButtons?: JSX.Element[];
  titleExtras?: JSX.Element[];
}

export interface DemmiCardPropsEditableContent extends Base {
  editMode: boolean;
  onClickEdit: () => void;
  onCancel: () => void;
  onSave: () => void;
  isValid: boolean;
}

export interface DemmiCardPropsTabbedContent extends Base {
  tabs: string[];
  tabContents: JSX.Element[];
  onClickTab: (index: number) => void;
}

export interface DemmiCardPropsSelfManagedTabbedContent extends Base {
  activeTab: number;
  tabs: string[];
  tabContents: JSX.Element[];
  onClickTab: (index: number) => void;
}

export interface Base extends PropsWithChildren, HTMLProps<HTMLDivElement> {
  rootClassName?: string;
  buttons?: JSX.Element[];
  title?: string;
  subtitle?: string;
  darkOnly?: boolean;
  isLoading?: boolean;
  collapsible?: boolean;
  collapsed?: boolean;
  noPadding?: boolean;
}

export type DemmiCardProps =
  | DemmiCardPropsDefault
  | DemmiCardPropsEditableContent
  | DemmiCardPropsSelfManagedTabbedContent
  | DemmiCardPropsTabbedContent;

/**
 * Typeguards
 */
export const isEditableContent = (
  props: DemmiCardProps,
): props is DemmiCardPropsEditableContent => {
  return 'editMode' in props;
};

const isTabbedContent = (
  props: DemmiCardProps,
): props is DemmiCardPropsTabbedContent => {
  return (
    !('activeTab' in props) &&
    'tabs' in props &&
    'tabContents' in props &&
    'onClickTab' in props
  );
};

export const isSelfManagedTabbedContent = (
  props: DemmiCardProps,
): props is DemmiCardPropsSelfManagedTabbedContent => {
  return (
    'activeTab' in props &&
    'tabs' in props &&
    'tabContents' in props &&
    'onClickTab' in props
  );
};

/**
 * Helpers
 */
export const useActiveTabState = (props: DemmiCardProps) => {
  if (isSelfManagedTabbedContent(props)) {
    return [props.activeTab, () => {}] as const;
  }
  return useState(0);
};

export const doPropsHaveTabs = (props: DemmiCardProps) => {
  const isTabbed =
    (isTabbedContent(props) || isSelfManagedTabbedContent(props)) &&
    props.tabs.length > 0;

  if (isTabbed && props.tabs.length !== props.tabContents.length) {
    Logger(
      {
        messages: ['Tabs !== TabContents length.'],
        objs: { tabs: props.tabs, tabContents: props.tabContents },
        type: DemmiLogType.error,
      },
      doPropsHaveTabs,
    );
    return null;
  }
  return isTabbed;
};

// Only allow spreading for props that won't cause console errors
export const getSpreadableProps = (props: DemmiCardProps) => {
  if (isEditableContent(props)) {
    const {
      editMode: _0,
      onClickEdit: _1,
      onCancel: _2,
      onSave: _3,
      isValid: _4,
      ...remainder
    } = props;
    return remainder;
  } else if (isTabbedContent(props)) {
    const { tabs: _0, tabContents: _1, onClickTab: _2, ...remainder } = props;
    return remainder;
  } else if (isSelfManagedTabbedContent(props)) {
    const {
      activeTab: _0,
      tabs: _1,
      tabContents: _2,
      onClickTab: _3,
      ...remainder
    } = props;
    return remainder;
  } else {
    const {
      titleButtons: _0,
      titleExtras: _1,
      ...remainder
    } = props as DemmiCardPropsDefault;
    return remainder;
  }
};
