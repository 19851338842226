import React from 'react';

import AsyncImage from '@components/asyncImage/AsyncImage';
import DemmiCard from '@demmi-ui/Card/Card';
import { DemmiFS, DemmiHelpers } from '@subhanhabib/demmilib';

interface Props {
  order: DemmiFS.Order;
}

const WidgetOrderItem: React.FC<Props> = ({ order }) => {
  const CSSBlock = 'widget-order-item';

  return (
    <>
      {order.vendor && (
        <DemmiCard>
          <div className={CSSBlock}>
            <div className={`${CSSBlock}__collection-info`}>
              <div className={`${CSSBlock}__collection-item`}>
                <div className={`${CSSBlock}__collection-item-heading`}>
                  Collection Code
                </div>
                <div className={`${CSSBlock}__collection-item-value`}>
                  {order.collectionCode}
                </div>
              </div>

              <div className={`${CSSBlock}__collection-item`}>
                <div className={`${CSSBlock}__collection-item-heading`}>
                  Collection Date
                </div>
                <div className={`${CSSBlock}__collection-item-value`}>
                  {order.vendor.collectionDate
                    ? `${order.vendor.collectionDate.day}/${order.vendor.collectionDate.month}/${order.vendor.collectionDate.year}`
                    : 'No date given'}
                </div>
              </div>

              <div className={`${CSSBlock}__collection-item`}>
                <div className={`${CSSBlock}__collection-item-heading`}>
                  Collection Time
                </div>
                <div className={`${CSSBlock}__collection-item-value`}>
                  {order.vendor.collectionTime
                    ? `${DemmiHelpers.DemmiTime.timeToString(
                        order.vendor.collectionTime
                      )} - ${DemmiHelpers.DemmiTime.timeToString(
                        order.vendor.collectionTime + 30
                      )}`
                    : 'No time given'}
                </div>
              </div>
            </div>

            <div className={`${CSSBlock}__products-list`}>
              {order.vendor.products.map((product, i) => (
                <div className={`${CSSBlock}__product`} key={i}>
                  <div className={`${CSSBlock}__product-image`}>
                    <AsyncImage src={product.image} />
                  </div>
                  <div className={`${CSSBlock}__product-name`}>
                    {product.name}
                    <div className={`${CSSBlock}__product-variant`}>
                      {product.variantName}
                    </div>
                  </div>
                  <div className={`${CSSBlock}__product-quantity`}>
                    {product.quantity}
                    <span className={`${CSSBlock}__product-quantity-unit`}>
                      {DemmiFS.UnitsHelper.getWeightUnitText(
                        product.variantUnit
                      )}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </DemmiCard>
      )}
    </>
  );
};

export default WidgetOrderItem;
