import React from 'react';
import { Country, parsePhoneNumber } from 'react-phone-number-input';

import { DemmiFS } from '@subhanhabib/demmilib';

export interface Props {
  review: DemmiFS.VendorReview;
  CSSBlock: string;
}

const ReviewCustomer: React.FC<Props> = ({ review, CSSBlock }) => {
  return (
    <div className={`${CSSBlock}__customer`}>
      {review.customer ? (
        <>
          <div className={`${CSSBlock}__title`}>Customer Information</div>
          <div className={`${CSSBlock}__customer-name`}>
            {review.customer.title} {review.customer.firstName}{' '}
            {review.customer.lastName}
          </div>
          <div className={`${CSSBlock}__customer-number`}>
            {parsePhoneNumber(
              review.customer.contactNumber,
              review.customer?.address?.countryCode as Country
            )?.formatInternational()}
          </div>
        </>
      ) : (
        <div className={`${CSSBlock}__not-found`}>Customer not found</div>
      )}
    </div>
  );
};

export default ReviewCustomer;
