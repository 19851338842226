export enum ThemeModeConstants {
  LIGHT = 'light',
  DARK = 'dark',
  SYSTEM = 'system',
}

export type ThemeMode =
  | ThemeModeConstants.LIGHT
  | ThemeModeConstants.DARK
  | ThemeModeConstants.SYSTEM;

export enum CSSVARS {
  PHONE_PREVIEW_HUE = '--phone-preview-hue',
  THEME_BG_HUE = '--theme-bg-hue',
  THEME_BG_01 = '--theme-bg-01',
  THEME_BG_02 = '--theme-bg-02',
  THEME_BG_POS = '--theme-bg-pos',
}

export const setCSSPhonePreviewPalette = (palette: MaterialColorPalette) => {
  Object.entries(palette).forEach(([key, val]) => {
    setCSSPhonePreviewPaletteVar(key, 's', val.s);
    setCSSPhonePreviewPaletteVar(key, 'l', val.l);
  });
};
export const setCSSPhonePreviewPaletteVar = (
  swatch: string,
  unit: 's' | 'l',
  value: number,
) =>
  document.documentElement.style.setProperty(
    `--phone-preview-palette-${swatch}-${unit}`,
    `${value.toString()}%`,
  );

export const setCSSPalette = (palette: MaterialColorPalette) => {
  Object.entries(palette).forEach(([key, val]) => {
    setCSSPaletteVar(key, 's', val.s);
    setCSSPaletteVar(key, 'l', val.l);
  });
};

export const setCSSPaletteVar = (
  swatch: string,
  unit: 's' | 'l',
  value: number,
) =>
  document.documentElement.style.setProperty(
    `--palette-${swatch}-${unit}`,
    `${value.toString()}%`,
  );

export const setCSSVar = (cssVar: CSSVARS, value: number | string) =>
  document.documentElement.style.setProperty(cssVar, value.toString());

export interface RGBA {
  r: number;
  g: number;
  b: number;
  a: number;
}
export interface HSL {
  h: number;
  s: number;
  l: number;
}

export const isHSL = (v: unknown): v is HSL => {
  return (
    typeof v === 'object' &&
    v !== null &&
    'h' in v &&
    's' in v &&
    'l' in v &&
    typeof v.h === 'number' &&
    typeof v.s === 'number' &&
    typeof v.l === 'number'
  );
};

export const colourToRGBA = (colour: number): RGBA => {
  // eslint-disable-next-line no-bitwise
  colour >>>= 0;
  // eslint-disable-next-line no-bitwise
  const b = colour & 0xff,
    // eslint-disable-next-line no-bitwise
    g = (colour & 0xff00) >>> 8,
    // eslint-disable-next-line no-bitwise
    r = (colour & 0xff0000) >>> 16,
    // eslint-disable-next-line no-bitwise
    a = ((colour & 0xff000000) >>> 24) / 255;
  return {
    r,
    g,
    b,
    a,
  };
};

export const colourToRGBAString = (colour: number): string => {
  const { r, g, b, a } = colourToRGBA(colour);
  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

export interface MaterialColorPalette {
  50: HSL;
  100: HSL;
  200: HSL;
  300: HSL;
  400: HSL;
  500: HSL;
  600: HSL;
  700: HSL;
  800: HSL;
  900: HSL;
}
export interface MaterialColor {
  hue: number;
  palette: MaterialColorPalette;
}

// ARGB format
export class DemmiTheme {
  static get(hue: number): MaterialColor {
    return hue >= 60 && hue <= 180
      ? DemmiTheme.generateThemeLightOLD(hue)
      : DemmiTheme.generateTheme(hue);
  }

  static generateTheme(h: number): MaterialColor {
    return {
      hue: h,
      palette: {
        50: { h, s: 35, l: 93 },
        100: { h, s: 40, l: 84 },
        200: { h, s: 45, l: 73 },
        300: { h, s: 50, l: 63 },
        400: { h, s: 55, l: 55 },
        500: { h, s: 60, l: 47 },
        600: { h, s: 65, l: 42 },
        700: { h, s: 70, l: 36 },
        800: { h, s: 75, l: 30 },
        900: { h, s: 80, l: 23 },
      },
    };
  }

  static generateThemeLight(h: number): MaterialColor {
    return {
      hue: h,
      palette: {
        50: { h, s: 30, l: 91 },
        100: { h, s: 30, l: 81 },
        200: { h, s: 32, l: 68 },
        300: { h, s: 32, l: 58 },
        400: { h, s: 38, l: 50 },
        500: { h, s: 45, l: 40 },
        600: { h, s: 42, l: 34 },
        700: { h, s: 50, l: 29 },
        800: { h, s: 70, l: 23 },
        900: { h, s: 90, l: 15 },
      },
    };
  }

  static generateThemeLightOLD(h: number): MaterialColor {
    return {
      hue: h,
      palette: {
        50: { h, s: 30, l: 91 },
        100: { h, s: 30, l: 78 },
        200: { h, s: 35, l: 64 },
        300: { h, s: 35, l: 55 },
        400: { h, s: 40, l: 50 },
        500: { h, s: 45, l: 42 },
        600: { h, s: 50, l: 36 },
        700: { h, s: 60, l: 29 },
        800: { h, s: 70, l: 26 },
        900: { h, s: 90, l: 20 },
      },
    };
  }
}
