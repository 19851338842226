import { Timestamp } from 'firebase/firestore';
import React, { useState } from 'react';

import ElementLoadingIndicator from '@components/loadingIntro/ElementLoadingIndicator';
import { updateRefundRequest } from '@core/services/networkService/refundRequests/refundRequests';
import DemmiButton from '@demmi-ui/Button';
import DemmiTextArea from '@demmi-ui/TextArea';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  CSSBlock: string;
  vendorID: string;
  refundRequest: DemmiFS.RefundRequest;
}

const OrderItemTabRefundUpdate: React.FC<Props> = ({
  CSSBlock,
  vendorID,
  refundRequest,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [text, setText] = useState('');

  const onClickAccept = () => {
    setIsUpdating(true);
    updateRefundRequest(refundRequest.docID, {
      text,
      timestamp: Timestamp.now(),
      vendorID,
      statusUpdate: DemmiFS.RefundHelper.RefundStatus.ACCEPTED,
      updatedBy: DemmiFS.RefundHelper.UpdatedBy.VENDOR,
    }).then(() => setIsUpdating(false));
  };

  const onClickReject = () => {
    setIsUpdating(true);
    updateRefundRequest(refundRequest.docID, {
      text,
      timestamp: Timestamp.now(),
      vendorID,
      statusUpdate: DemmiFS.RefundHelper.RefundStatus.REJECTED,
      updatedBy: DemmiFS.RefundHelper.UpdatedBy.VENDOR,
    }).then(() => setIsUpdating(false));
  };

  const onClickDispute = () => {
    setIsUpdating(true);
    updateRefundRequest(refundRequest.docID, {
      text,
      timestamp: Timestamp.now(),
      vendorID,
      statusUpdate: DemmiFS.RefundHelper.RefundStatus.DISPUTED,
      updatedBy: DemmiFS.RefundHelper.UpdatedBy.VENDOR,
    }).then(() => setIsUpdating(false));
  };

  const onClickUpdate = () => {
    setIsUpdating(true);
    updateRefundRequest(refundRequest.docID, {
      text,
      timestamp: Timestamp.now(),
      vendorID,
      updatedBy: DemmiFS.RefundHelper.UpdatedBy.VENDOR,
    }).then(() => setIsUpdating(false));
  };

  const statusRequestedButtons = () => [
    <DemmiButton
      text="Reject Refund"
      faIcon="fa-xmark"
      variant="delete"
      onClick={onClickReject}
      key={0}
    />,
    <DemmiButton
      text="Accept Refund"
      faIcon="fa-check"
      variant="save"
      onClick={onClickAccept}
      key={1}
    />,
  ];

  const statusOther = () => [
    <DemmiButton
      text="Raise Dispute"
      faIcon="fa-regular fa-message-xmark"
      onClick={onClickDispute}
      key={0}
    />,
    <DemmiButton
      text="Send Update"
      faIcon="fa-paper-plane-top"
      onClick={onClickUpdate}
      key={1}
    />,
  ];

  return (
    <div
      className={`${CSSBlock}__update ${
        isUpdating ? `${CSSBlock}__update--is-loading` : ``
      }`}>
      <div className={`${CSSBlock}__update-title`}>Update status</div>
      <DemmiTextArea
        placeholder="Enter an update"
        value={text}
        onChange={t => setText(t)}
      />
      <div className={`${CSSBlock}__buttons`}>
        {DemmiFS.RefundHelper.getCurrentRefundStatus(refundRequest) ===
        DemmiFS.RefundHelper.RefundStatus.REQUESTED
          ? statusRequestedButtons()
          : statusOther()}
        {statusRequestedButtons()}
      </div>
      <ElementLoadingIndicator isLoading={isUpdating} />
    </div>
  );
};

export default OrderItemTabRefundUpdate;
