import React from 'react';

import DemmiCard from '@demmi-ui/Card/Card';
import DemmiInput from '@demmi-ui/Input';
import Menu from '@demmi-ui/Menu/Menu';
import MenuTriggerButton from '@demmi-ui/Menu/MenuTriggerButton';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  CSSBlock: string;
  vendorAddress: DemmiFS.VendorAddress;
  setVendorAddress: React.Dispatch<
    React.SetStateAction<DemmiFS.VendorAddress | undefined>
  >;
}

const RegionAddressFields: React.FC<Props> = ({
  CSSBlock,
  vendorAddress,
  setVendorAddress,
}) => {
  const addressDefinitions = DemmiFS.DemmiAddressHelper.getFieldDefinitions(
    vendorAddress?.countryCode ?? ''
  );

  const updateAddressField = (
    fieldKey: keyof DemmiFS.DemmiAddressFields,
    value: string
  ) => {
    if (vendorAddress?.address) {
      const updatedAddress: DemmiFS.VendorAddress = {
        ...vendorAddress,
        address: { ...vendorAddress.address, [fieldKey]: value },
      };
      setVendorAddress(updatedAddress);
    }
  };

  const getMenuContent = (items: string[]) => {
    return {
      sections: [
        {
          items: items.map(c => ({
            id: c.replaceAll(' ', ''),
            text: c,
            item: c,
          })),
        },
      ],
    };
  };

  return (
    <DemmiCard title="Vendor Address" className={`${CSSBlock}__address`}>
      <div className={`${CSSBlock}__inputs-area`}>
        {vendorAddress &&
          addressDefinitions &&
          Object.entries(addressDefinitions).map((entry, i) => {
            const [key, value] = entry as [
              keyof DemmiFS.DemmiAddressFields,
              DemmiFS.DemmiAddressFieldDefinition
            ];

            return (
              <div className={`${CSSBlock}__details-item`} key={i}>
                <div className={`${CSSBlock}__details-title`}>
                  {value.label}
                </div>
                {value.values ? (
                  <Menu<string>
                    content={getMenuContent(Object.values(value.values))}
                    triggerEl={
                      <MenuTriggerButton
                        disabled
                        text={
                          (vendorAddress.address &&
                            vendorAddress.address[key]) ??
                          ''
                        }
                      />
                    }
                    onClick={val => updateAddressField(key, val)}
                  />
                ) : (
                  <DemmiInput
                    type="text"
                    disabled
                    placeholder={value.hint}
                    value={
                      vendorAddress?.address ? vendorAddress.address[key] : ''
                    }
                    maxLength={value.maxLength}
                    onChangeValue={val => updateAddressField(key, val)}
                  />
                )}
              </div>
            );
          })}
      </div>
    </DemmiCard>
  );
};

export default RegionAddressFields;
