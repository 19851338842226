import { doc, getDoc } from 'firebase/firestore';

import { DemmiFS } from '@subhanhabib/demmilib';

import { db } from '../../../../firebase';
import { FSCollectionNames } from '../networkService';

export const getFSDemmiConstants = async (): Promise<
  DemmiFS.FSDemmiConstants | undefined
> => {
  return getDoc(doc(db, FSCollectionNames.DEMMI, 'constants')).then(doc => {
    if (doc.exists()) {
      const demmiConstants: DemmiFS.FSDemmiConstants =
        doc.data() as DemmiFS.FSDemmiConstants;

      demmiConstants.categories = demmiConstants.categories.map(parent => ({
        ...parent,
        icon: DemmiFS.CategoriesHelper.getIconFromOnlyCategoryID(parent.id),
        children: parent.children.map(child => ({
          ...child,
          icon: DemmiFS.CategoriesHelper.getIconFromOnlyCategoryID(child.id),
        })),
      }));
      return demmiConstants;
    }
    return undefined;
  });
};
