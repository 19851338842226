import { arrayUnion, doc, updateDoc } from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';
import { DemmiFS, DemmiLogType, Logger } from '@subhanhabib/demmilib';

import { orderQuery } from './_queries';

export const updateOrderStatus = async (
  orderID: string,
  update: DemmiFS.OrderTimelineUpdate,
): Promise<void> => {
  const order = await orderQuery(orderID);
  if (!order) {
    Logger(
      {
        messages: ['Failed to find order to push update.'],
        objs: { orderID, update },
        type: DemmiLogType.error,
      },
      updateOrderStatus,
    );
    return;
  }

  const docRef = doc(FSCollections.Orders, orderID);
  return updateDoc(docRef, {
    timeline: arrayUnion(update),
  });
};
