import {
  PayloadAction,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';

import appReducer from './appSlice/appSlice';
import userReducer from './userSlice/userSlice';

const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state: any, action: PayloadAction) => {
  // if (action.type === 'LOGOUT') {
  //   return rootReducer(undefined, action);
  // }
  return rootReducer(state, action);
};

export const store = configureStore({
  reducer: reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }),
  // devTools: process.env.NODE_ENV !== 'production'
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
