import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from '@core/hooks/appHooks';
import {
  deleteCoupon,
  getCoupons,
} from '@core/services/networkService/functions/_coupons';
import { SearchParams } from '@core/services/routingService';
import { selectVendor } from '@core/store/appSlice/appSlice';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

import CouponDetail from './CouponDetail';
import CouponsSidebar from './CouponsSidebar';

const Coupons: React.FC = () => {
  const CSSBlock = 'coupons';
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCoupon, setSelectedCoupon] = useState<DemmiFS.Coupon>();
  const vendor = useAppSelector(selectVendor);
  const [coupons, setCoupons] = useState<DemmiFS.Coupon[]>([]);

  useEffect(() => {
    setCoupons([]);
    if (vendor) {
      demmiRequest(getCoupons(vendor.docID)).then(c => {
        setCoupons(c);
        if (searchParams.get(SearchParams.COUPON_ID)) {
          setSelectedCoupon(
            c.find(c => c.docID === searchParams.get(SearchParams.COUPON_ID)),
          );
        } else {
          setSelectedCoupon(undefined);
        }
      });
    }
  }, [vendor]);

  const onClickRefresh = () => {
    if (vendor) {
      demmiRequest(getCoupons(vendor.docID)).then(c => {
        setCoupons(c);
      });
      setSelectedCoupon(undefined);
    }
  };

  const onCreated = async (code: string) => {
    if (vendor) {
      await demmiRequest(getCoupons(vendor.docID)).then(coupons => {
        setCoupons(coupons);
        setSelectedCoupon(coupons.find(c => c.docID === code));
      });
    }
  };

  const onClickDelete = (code: string) => {
    if (vendor)
      demmiRequest(deleteCoupon(code, vendor.docID)).then(() =>
        onClickRefresh(),
      );
  };

  const onClickCoupon = (coupon: DemmiFS.Coupon) => {
    setSearchParams({ [SearchParams.COUPON_ID]: coupon.docID });
    setSelectedCoupon(coupon);
  };

  const onUpdated = (coupon: DemmiFS.Coupon) => {
    const i = coupons.findIndex(c => c.docID === coupon.docID);
    if (i > -1) {
      setCoupons([...coupons.slice(0, i), coupon, ...coupons.slice(i + 1)]);
      setSelectedCoupon(coupon);
    }
  };

  return (
    <div className={`${CSSBlock}`}>
      <CouponsSidebar
        selectedCoupon={selectedCoupon}
        onClick={onClickCoupon}
        onClickRefresh={onClickRefresh}
        onCreated={onCreated}
        coupons={coupons}
      />
      {selectedCoupon && vendor && (
        <CouponDetail
          vendor={vendor}
          coupon={selectedCoupon}
          onClickDelete={onClickDelete}
          onUpdated={onUpdated}
        />
      )}
    </div>
  );
};

export default Coupons;
