import { DateTime } from 'luxon';
import React from 'react';

import ModalEditCoupon from '@components/modals/EditCoupon/ModalEditCoupon';
import ModalDelete from '@components/modals/ModalDelete';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  vendor: DemmiFS.Vendor;
  coupon: DemmiFS.Coupon;
  onClickDelete: (code: string) => void;
  onUpdated: (coupon: DemmiFS.Coupon) => void;
}

const CouponDetail: React.FC<Props> = ({
  vendor,
  coupon,
  onClickDelete,
  onUpdated,
}) => {
  const CSSBlock = 'coupon-detail';

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__wrapper`}>
        <div className={`${CSSBlock}__wrapper-background`}></div>
        <div className={`${CSSBlock}__content`}>
          <div className={`${CSSBlock}__code`}>{coupon.docID}</div>
          <div className={`${CSSBlock}__content-details`}>
            <div className={`${CSSBlock}__type`}>{coupon.type}</div>
            <div className={`${CSSBlock}__detail-split`}>
              {coupon.amount && (
                <div className={`${CSSBlock}__detail`}>
                  <div className={`${CSSBlock}__detail-key`}>
                    Discount Amount
                  </div>
                  <div className={`${CSSBlock}__detail-value`}>
                    {DemmiFS.CouponHelper.getAmount(
                      DemmiFS.CurrencyHelper.getCurrencySymbolFromCountryCode(
                        vendor.address.countryCode
                      ),
                      coupon
                    )}
                  </div>
                </div>
              )}
              {coupon.minSpend !== null && coupon.minSpend > 0 && (
                <div className={`${CSSBlock}__detail`}>
                  <div className={`${CSSBlock}__detail-key`}>Minimum Spend</div>
                  <div className={`${CSSBlock}__detail-value`}>
                    {DemmiFS.CurrencyHelper.getCurrencySymbolFromCountryCode(
                      vendor.address.countryCode
                    )}
                    {coupon.minSpend}
                  </div>
                </div>
              )}
            </div>
            <div className={`${CSSBlock}__detail-split`}>
              {coupon.expirationDate && (
                <div className={`${CSSBlock}__detail`}>
                  <div className={`${CSSBlock}__detail-key`}>
                    Expiration Date
                  </div>
                  <div className={`${CSSBlock}__detail-value`}>
                    {DateTime.fromJSDate(
                      DemmiFS.getDateFromFirestore(coupon.expirationDate)!
                    ).toLocaleString()}
                  </div>
                </div>
              )}
              {coupon.startDate && (
                <div className={`${CSSBlock}__detail`}>
                  <div className={`${CSSBlock}__detail-key`}>Start Date</div>
                  <div className={`${CSSBlock}__detail-value`}>
                    {DateTime.fromJSDate(
                      DemmiFS.getDateFromFirestore(coupon.startDate)!
                    ).toLocaleString()}
                  </div>
                </div>
              )}
            </div>
            <div className={`${CSSBlock}__status`}>
              {DemmiFS.CouponHelper.getStatus(coupon)}
            </div>
          </div>
        </div>
      </div>
      <div className={`${CSSBlock}__buttons`}>
        <ModalEditCoupon
          onClose={() => {}}
          onConfirm={c => onUpdated(c)}
          coupon={coupon}
        />

        <ModalDelete
          onClose={() => {}}
          onConfirm={() => onClickDelete(coupon.docID)}
          name={coupon.docID}
        />
      </div>
    </div>
  );
};

export default CouponDetail;
