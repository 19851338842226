import React from 'react';

interface Props {
  app: number;
  setApp: React.Dispatch<React.SetStateAction<number>>;
}

const FakePhoneAppSwitcher: React.FC<Props> = ({ app, setApp }) => {
  const CSSBlock = 'app-switcher';

  return (
    <div className={CSSBlock}>
      <div
        className={`${CSSBlock}__button ${
          app === 0 ? `${CSSBlock}__button--active` : ''
        }`}
        onClick={() => setApp(0)}>
        <span className={`${CSSBlock}__icon`}>
          <i className={`fa-solid fa-shop`}></i>
        </span>
        Your Shop
      </div>
      <div
        className={`${CSSBlock}__button ${
          app === 1 ? `${CSSBlock}__button--active` : ''
        }`}
        onClick={() => setApp(1)}>
        <span className={`${CSSBlock}__icon`}>
          <i className={`fa-solid fa-tag`}></i>
        </span>
        Product Page
      </div>
    </div>
  );
};

export default FakePhoneAppSwitcher;
