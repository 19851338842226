import React from 'react';

import AppTheme from '@core/AppTheme';

const NotFound404Route: React.FC = () => {
  const CSSBlock = 'not-found-404';

  return (
    <React.Fragment>
      <AppTheme isLogin={false} />
      <div className={`${CSSBlock}__wrapper`}>
        <div className={`${CSSBlock}__content`}>
          <div className={`${CSSBlock}__title`}>
            <span>.:.</span> Oopsie! <span>.:.</span>
          </div>
          <div className={`${CSSBlock}__subtitle`}>
            Looks like you got lost in the fields
          </div>
          <div className={`${CSSBlock}__description`}>
            We&apos;re still under development here at Demmi, so it&apos;s
            possible the page you were looking for isn&apos;t sprouted yet.
            Don&apos;t worry, fresh features are always being harvested!
          </div>
          <div className={`${CSSBlock}__description`}>
            Sign up for our newsletter and be the first to know about the
            juiciest updates from Demmi!
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default NotFound404Route;
