import React, { useState } from 'react';

import { useAuth } from '@providers/authProvider';
import { Logger } from '@subhanhabib/demmilib';

const LoadingAuth: React.FC = () => {
  const CSSBlock = 'loading-intro';

  const [hide, _] = useState(false);

  const onLoad = () => {
    const { user } = useAuth();
    Logger({ messages: ['onLoad'], objs: { user } }, LoadingAuth);
    if (!user) return;
  };

  onLoad();

  return (
    <div
      className={`${CSSBlock} ${CSSBlock}--auth ${
        hide ? `${CSSBlock}--hide` : ''
      }`}>
      <div className={`${CSSBlock}__background`}></div>
      <div className={`${CSSBlock}__title`}>
        <strong>P</strong>roject <strong>D</strong>eme
      </div>
      <div className={`${CSSBlock}__subtitle`}>Checking User Data</div>
      <div className={`${CSSBlock}__loading-indicator`}></div>
    </div>
  );
};

export default LoadingAuth;
